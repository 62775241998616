<template class="">
<div class="container-main-admin" >
        <div class="container-admin">
          <div id="square"></div>
        
          <div class="container-text">
            <div class="container-logo-admin">
              <img src="../assets/Recurso 16.png" alt="" height="100px" />
            </div>
            <div class="container-description">
              <h1>
                Bienvenid@ a nuestro <br />
                ambiente de evaluación
              </h1>
              <p>
                Nuestra plataforma de evaluación oral está diseñada <br> para evaluar la habilidad oral del participante
              </p>

            </div>
          </div>
          <div class="container-img">
              <div id="logo-page-admin"></div>
          </div>
        </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      administrativeSrc: require('../assets/administrative_image.jpg'),
      logoSrc: require('../assets/logo_BBE_avatar.png'),
      alignments: [
        'start',
        'center',
        'end',
      ],
    }
  }
}
</script>


<style media="screen" scoped>
.container-main-admin {
  margin: 0;
  padding: 0 6%;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
  /* width: 100%; */
  /* margin-top: 10%; */
  margin:70px auto;
  max-width: 1200px;
}

.container-text {
  /* margin-right: 30px; */
}
.container-img {
  height: calc(65vh);
  width: 50%;
  background: url("../assets/Recurso 17-100.jpg");
  background-color: #fff;
  background-position: center;
  background-size: cover;
  z-index: 1;
}

.container-logo-admin {
  object-fit: cover;
  margin-bottom: 20px;
}
.container-description h1 {
  letter-spacing: 2px;
  margin-bottom: 5px;
  color: black;
  text-align: left;
}

.container-description p {
  font-size: 20px;
  color: black;
  text-align: left;
}

.btn-start {
  color: white !important;
  background-color: #005cf2 !important;
}
.container-admin {
   display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 80vh; */
  /* width: 80%; */
  /* margin-left: 150px; */
}
#square {
   background: linear-gradient(
    rgba(10, 95, 166, 0.99),
    rgba(105, 61, 181, 0.99)
  );
  height: 40%;
  width: 30vw;
  position: absolute;
  right: 0;
  bottom: 0;
}
#logo-page-admin {
  height: 50px;
  width: 78px;
  background: url("../assets/Recurso 16-100.jpg");
  background-size: cover;
  position: relative;
  top: 40px;
  right: -90%;
  z-index: 25;
}

@media (min-width: 320px) and (max-width: 765px) {

 .container-text{
   margin-right: 0;
   padding: 5px 8%;
 }
.container-main-admin {
  /* height: 110vh; */
  height: auto;
}

#logo-page-admin, .container-img , #square{
  display: none;
}

.container-admin{
  /* margin-left: calc(20%) */
  width: 100%;
  height: auto;
  margin-left: 0;
}

.container-description h1 {
  letter-spacing: 1px;
  font-size: 2.5rem;
}

.container-description p {
  font-size: 1.5rem;
}


}

@media (max-width: 340px){
  #logo-page-admin, #square{
  display: none;
}

.container-admin{
  margin-top: 10%;
  margin-left: calc(25%)
}
}

@media (min-width: 768px) and (max-width: 1024px){
  .container-description h1{
    font-size: 1.5em;
  }
  .container-description p{
    font-size: 1.1em;
  }
  .container-admin{
    margin-left: 10%;
  }
} 
</style>
