<template>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-text-field v-model="filterParams.student_name" label="Name">
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="filterParams.student_cedula"
            label="Cedula"
          >
          </v-text-field>
        </v-col>

      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="filterParams.student_company"
            :items="getDropdownCompanies"
            item-text="name"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            label="Select Company"
            chips
            multiple
            solo
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" align="right">
          <v-btn
          elevation="2"
          large
          x-large
          dark
          color="bbe-blue"
          @click="filter"
          >Filter</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([ "getDropdownCompanies" ])
  },
  data() {
    return {
      filterParams: {
        student_cedula: "",
        student_name: "",
        student_company: ""
      }
    }
  },
  methods: {
    ...mapActions([
      "filterData",
      "loadCompaniesDropdown",
      "setStudentFilterStatus"
    ]),
    filter() {
      this.filterData(this.filterParams)
      this.setStudentFilterStatus()
    }
  },
  name: 'StudentFiler'
}
</script>
