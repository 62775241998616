<template id="">
  <div>
    <Setting :settings="getSettings" />
  </div>
</template>
<script>
import Setting from "@/components/settings/Setting";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Settings",
  data() {
    return { };
  },
  components: {
    Setting
  },
  computed: {
    ...mapGetters(["getSettings"])
  },
  methods: {
    ...mapActions(["loadSettings"])
  },
  created() {
    this.loadSettings();
  }
}
</script>
