<template>
  <v-card v-if="hasResults()" class="mb-1 pa-1 green darken-4" dark>
    <v-card-title class="headline justify-center">
      RESULTS
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <ul>
        <li v-for="(value, key) in results" :key="key">
          {{ value }}
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    results: {
      type: Array,
      required: true
    }
  },
  methods: {
    hasResults() {
      return Object.keys(this.results).length > 0;
    }
  },
  name: "Results"
};
</script>

<style scoped lang="scss">

</style>
