import api from "@/api";

const actions = {
  async createQuestion({ commit, dispatch }, question) {
    await api
      .createQuestion(question)
      .then(response => {
        commit("ADD_TO_QUESTIONS", response.data);
        commit("ADD_RESULTS", response.data);
        dispatch("setQuestionDialogEditStatus");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async deleteQuestion({ commit }, question) {
    await api
      .deleteQuestion(question)
      .then(response => {
        commit("ADD_RESULTS", response.data);
        commit("REMOVE_FROM_QUESTIONS", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async loadQuestions({ commit, dispatch }) {
    await api
      .loadQuestions()
      .then(response => {
        commit("LOAD_QUESTIONS", response.data);
        dispatch("changeIsLoadingState");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async loadQuestionsDropdown({ commit }) {
    await api.loadQuestionsDropdown().then(response => {
      commit("SET_QUESTIONS_DROPDOWN", response.data);
    });
  },

  async loadQuestionLevelsDropdown({ commit }) {
    await api.loadQuestionLevelsDropdown().then(response => {
      commit("SET_QUESTION_LEVELS_DROPDOWN", response.data);
    });
  },

  async initializeQuestion({ commit, dispatch }) {
    commit("INITIALIZE_RESULTS");
    commit("INITIALIZE_ERRORS");
    commit("INITIALIZE_QUESTION");
    dispatch("changeIsLoadingState");
  },
  async updateQuestion({ commit, dispatch }, question) {
    await api
      .updateQuestion(question)
      .then(response => {
        commit("UPDATE_QUESTION", response.data);
        commit("ADD_RESULTS", response.data);
        dispatch("setQuestionDialogEditStatus");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  setQuestionDialogEditStatus({ commit }) {
    commit("SET_QUESTION_DIALOG_EDIT_STATUS");
  },

  async filterQuestions({ commit }, filterParams) {
    await api.loadQuestions(filterParams).then(response => {
      commit("SET_FILTERED_QUESTIONS", response.data);
    });
  }
};

const getters = {
  getDropdownQuestions: state => state.dropdownQuestions,
  getDropdownQuestionLevels: state => state.dropdownLevels,
  getQuestions: state => state.questions,
  getQuestionDialogEdit: state => state.questionDialogEdit
};

const mutations = {
  ADD_TO_QUESTIONS: (state, question) => {
    state.questions.push(question.question);
  },
  INITIALIZE_QUESTION: state => {
    state.questions = [];
  },
  LOAD_QUESTIONS: (state, questions) => {
    for (var i = 0; i < questions.length; i++) {
      state.questions.push(questions[i]);
    }
  },
  REMOVE_FROM_QUESTIONS: (state, payload) => {
    let questionIndex = state.questions.findIndex(
      x => x.id === payload.question.id
    );
    state.questions.splice(questionIndex, 1);
  },
  SET_QUESTIONS_DROPDOWN: (state, questions) => {
    for (var i = 0; i < questions.length; i++) {
      state.dropdownQuestions.push(questions[i]);
    }
  },

  SET_QUESTION_LEVELS_DROPDOWN: (state, levels) => {
    for (var i = 0; i < levels.length; i++) {
      state.dropdownLevels.push(levels[i]);
    }
  },

  UPDATE_QUESTION: (state, question) => {
    question = question.question;
    let questionIndex = state.questions.findIndex(x => x.id == question.id);
    state.questions.splice(questionIndex, 1, question);
  },

  SET_FILTERED_QUESTIONS: (state, questions) => {
    state.questions = [];
    for (var i = 0; i < questions.length; i++) {
      state.questions.push(questions[i]);
    }
  },

  SET_QUESTION_DIALOG_EDIT_STATUS: state => {
    state.questionDialogEdit = !state.questionDialogEdit;
  }
};

const state = {
  dropdownQuestions: [],
  questions: [],
  dropdownLevels: [],
  questionDialogEdit: false
};

export default {
  actions,
  getters,
  mutations,
  state
};
