<template >
  <v-dialog v-model="getUploadStudentsCVS" max-width="500px">
    <v-card hover>
      <v-card-title class="headline justify-center">
        Upload Students CVS
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row v-for="(file, key) in files" :key="file.name">
            <v-col cols="12" sm="12" class="font-weight-bold text-center">
              {{ file.name }}
              <v-icon small @click="removeFile(key)" class="mx-5">
                mdi-delete
              </v-icon>
            </v-col>
            <v-col cols="12" sm="3" class="font-weight-bold text-left">

              <!-- <v-btn
                @click="removeFile(key)"
                class="mx-auto"
                color="red accent-4"
                elevation="2"
                >
                Remove
              </v-btn> -->


            </v-col>
          </v-row>
          <v-row class="mx-auto">
            <v-btn
              @click="onPickFile"
              class="mx-auto"
              color="primary"
              elevation="6"
              large
              raised
              v-if="selectedFile"
              x-large
              >
              Select File
            </v-btn>

            <input
              @change="handleFilesUpload()"
              accept=".csv,.xlsx"
              id="files"
              ref="files"
              style="display:none"
              type="file"
            />
            <v-btn
              @click="submitFiles()"
              class="mx-auto"
              color="success"
              elevation="6"
              large
              raised
              v-if="!selectedFile"
              x-large
            >
              Upload
            </v-btn>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
// import axios from "axios"
export default {
  computed: {
    ...mapGetters([
      "getUploadStudentsCVS"
    ])
  },
  data(){
    return {
      files: [],
      selectedFile: true
    }
  },
  methods: {
    ...mapActions(["submitStudentCVS"]),
    onPickFile() {
      this.$refs.files.click();
    },
    // Adds a file
    addFiles(){
      this.$refs.files.click();
    },
    // Submits files to the server
    submitFiles(){
      // Initialize the form data
      let formData = new FormData();
      // Iteate over any file sent over appending the filesto the form data.
      for( var i = 0; i < this.files.length; i++ ){
        let file = this.files[i];
        formData.append('files[' + i + ']', file);
      }
      // Make the request to the POST /select-files URL
      this.submitStudentCVS(formData);
    },
    handleFilesUpload(){
      let uploadedFiles = this.$refs.files.files;
      // Adds the uploaded file to the files array
      for( var i = 0; i < uploadedFiles.length; i++ ){
        this.files.push( uploadedFiles[i] );
      }
      this.selectedFile = false
    },
    // Removes a select file the user has uploaded
    removeFile( key ){
      this.files.splice( key, 1 );
      this.selectedFile = true
    }
  }
}
</script>
