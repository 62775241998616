import api from "@/api";

const actions = {
  async createStudent({ commit, dispatch }, student) {
    if (student.email) {
      student.email = student.email.trim();
    }
    await api
      .createStudent(student)
      .then(response => {
        commit("ADD_TO_STUDENTS", response.data);
        commit("ADD_RESULTS", response.data);
        dispatch("cleanStudent"); 
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  async deleteStudent({ commit }, student) {
    await api
      .deleteStudent(student)
      .then(response => {
        commit("ADD_RESULTS", response.data);
        commit("REMOVE_FROM_STUDENTS", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  async filterData({ commit }, filterParams) {
    await api.loadStudents(filterParams).then(response => {
      commit("SET_FILTERED_STUDENTS", response.data);
    });
  },

  async initializeStudent({ commit, dispatch }) {
    commit("INITIALIZE_RESULTS");
    commit("INITIALIZE_ERRORS");
    commit("INITIALIZE_STUDENT");
    dispatch("changeIsLoadingState");
  },

  async loadStudents({ commit, dispatch }) {
    await api
      .loadStudents()
      .then(response => {
        commit("LOAD_STUDENTS", response.data);
        dispatch("changeIsLoadingState");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async loadStudentsEnable({ commit }) {
    await api
      .loadStudents()
      .then(response => {
        commit("LOAD_STUDENTS", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  async loadStudentLanguages({ commit }) {
    await api
      .loadStudentLanguages()
      .then(response => {
        commit("LOAD_STUDENT_LANGUAGES", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  setEditedStudent({ commit }, selectedStudent) {
    commit("SET_EDITED_STUDENT", selectedStudent);
  },

  setStudentDialogEditStatus({ commit }) {
    commit("SET_STUDENT_DIALOG_EDIT_STATUS");
  },

  setStudentFilterStatus({ commit }) {
    commit("SET_STUDENT_FILTER_STATUS");
  },

  async submitStudentCVS({ commit, dispatch }, formData) {
    dispatch("changeIsLoadingState");
    await api
      .submitStudentCVS(formData)
      .then(response => {
        commit("INITIALIZE_STUDENT");
        dispatch("loadStudents");
        commit("ADD_RESULTS", response.data);
        commit("SHOW_CVS_IMPORT_DIALOG", false);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
        dispatch("changeIsLoadingState");
        commit("SHOW_CVS_IMPORT_DIALOG", false);
      });
  },

  async updateStudent({ commit, dispatch }, student) {
    if (student.email) {
      student.email = student.email.trim();
    }
    await api
      .updateStudent(student)
      .then(response => {
        commit("UPDATE_STUDENT", response.data);
        commit("ADD_RESULTS", response.data);
        dispatch("cleanStudent");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  async uploadStudentsCVS({ commit }) {
    commit("SHOW_CVS_IMPORT_DIALOG", true);
  },

  async updateStudentHome({commit},student){
     const id = localStorage.getItem('idUser')
    await api.updateStudentName(student,id)
    .then(response =>{
      commit("UPDATE_STUDENT_HOME",response.data)
    })
  },

  cleanStudent({commit,dispatch}){ 
     commit("CLEAN_STUDENT")
     dispatch("setStudentDialogEditStatus")
  },

  async enableTest({commit},student){
    await api.enableTest(student)
    .then( response => {
      commit("ENABLE_TEST",response.data);
      commit("ADD_RESULTS", response.data);
    })
    .catch(error => {
      commit("ADD_ERRORS", error);
    });
    // .then(response => console.log(response))
  },

  async reSendEmailStudent({commit},id){
    await api.reSendEmail(id).
    then(response => {
      commit("ADD_RESULTS",response.data)
    })
    .catch(error => {
      commit("ADD_ERRORS",error)
    })
  }
};

const getters = {
  getActiveQuestionaty: state => state.activeQuestionary,
  getEditedStudent: state => state.editedStudent,
  getDefaultStudent: state => state.defaultStudent,
  getStudentDialogEdit: state => state.studentDialogEdit,
  getStudents: state => state.students,
  getStudentFilterStatus: state => state.filterStudentStatus,
  getStudentLanguages: state => state.studentLanguages,
  getUploadStudentsCVS: state => state.uploadStudentsCVS
};

const mutations = {
  ADD_TO_STUDENTS: (state, student) => {
    state.students.push(student.student);
  },
  HIDE_CVS_IMPORT_DIALOG: state => {
    state.uploadStudentsCVS = false;
  },
  INITIALIZE_STUDENT: state => {
    state.students = [];
  },
  LOAD_STUDENTS: (state, students) => {
    state.students = [];
    for (var i = 0; i < students.length; i++) {
      state.students.push(students[i]);
    }
  },
  LOAD_STUDENT_LANGUAGES: (state, languages) => {
    state.studentLanguages = [];
    for (var i = 0; i < languages.length; i++) {
      state.studentLanguages.push(languages[i]);
    }
  },
  REMOVE_FROM_STUDENTS: (state, payload) => {
    let studentIndex = state.students.findIndex(
      x => x.id === payload.student.id
    );
    state.students.splice(studentIndex, 1);
  },
  SET_EDITED_STUDENT: (state, selectedStudent) => {
    state.editedStudent.id = selectedStudent.id;
    state.editedStudent.address = selectedStudent.address;
    state.editedStudent.cedula = selectedStudent.cedula;
    state.editedStudent.cell_phone = selectedStudent.cell_phone;
    state.editedStudent.city = selectedStudent.city;
    state.editedStudent.company = selectedStudent.company;
    state.editedStudent.company_id = selectedStudent.company_id;
    state.editedStudent.email = selectedStudent.email;

    if (selectedStudent.programmed_date === null) {
      state.editedStudent.programmed_date = "";
    } else {
      let newDate = selectedStudent.programmed_date.split("T");
      state.editedStudent.programmed_date = newDate[0];
    }

    state.editedStudent.language_ids = [];
    if (
      selectedStudent.languages !== undefined &&
      selectedStudent.languages.length >= 0
    ) {
      for (var i = 0; i < selectedStudent.languages.length; i++) {
        state.editedStudent.language_ids.push(selectedStudent.languages[i].id);
      }
    }
    state.editedStudent.languages = selectedStudent.languages;
    state.editedStudent.name = selectedStudent.name;
    state.editedStudent.response_email_to = selectedStudent.response_email_to;
  },
  SET_FILTERED_STUDENTS: (state, students) => {
    state.students = [];
    for (var i = 0; i < students.length; i++) {
      state.students.push(students[i]);
    }
  },
  SET_STUDENT_DIALOG_EDIT_STATUS: state => {
    state.studentDialogEdit = !state.studentDialogEdit;
  },
  SET_STUDENT_FILTER_STATUS: state => {
    state.filterStudentStatus = !state.filterStudentStatus;
  },
  SHOW_CVS_IMPORT_DIALOG: (state, newState) => {
    state.uploadStudentsCVS = newState;
  },
  UPDATE_STUDENT: (state, student) => {
    let students = state.students;
    let student_to_replace = state.students.findIndex(
      x => x.id == student.student.id
    );
    students[student_to_replace] = student.student;
    state.students = [];
    for (var i = 0; i < students.length; i++) {
      state.students.push(students[i]);
    }
    state.editedStudent = state.defaultStudent;
  },
  UPDATE_STUDENT_HOME:(response) =>{
    console.log(response)
  },
  CLEAN_STUDENT:(state) =>{
   let student ={
      id: "",
      address: "",
      cedula: "",
      cell_phone: "",
      city: "",
      company: "",
      company_id: "",
      email: " ",
      language_ids: [],
      languages: [],
      name: "",
      programmed_date: "",
      response_email_to: ""
    }
    state.editedStudent = student
  },

  ENABLE_TEST:(student) =>{
    console.log(student)
  }
};

const state = {
  activeQuestionary: false,
  defaultStudent: {
    id: "",
    address: "",
    cedula: "",
    cell_phone: "",
    city: "",
    company: "",
    company_id: "",
    email: "",
    languages: [],
    language_ids: [],
    name: "",
    programmed_date: "",
    response_email_to: ""
  },
  editedStudent: {
    id: "",
    address: "",
    cedula: "",
    cell_phone: "",
    city: "",
    company: "",
    company_id: "",
    email: "",
    language_ids: [],
    languages: [],
    name: "",
    programmed_date: "",
    response_email_to: ""
  },
  errors: [],
  filterStudentStatus: false,
  students: [],
  studentDialogEdit: false,
  studentLanguages: [],
  uploadStudentsCVS: false
};

export default {
  actions,
  getters,
  mutations,
  state
};
