<template>
  <div class="">
    <Loading v-if="isInfoLoading"/>
    <v-card v-else class="mx-3 my-5 pa-1">
      <Results :results="getResults" />
      <UploadCSV />

      <v-toolbar color="bbe-blue" dark >

        <v-toolbar-title>STUDENTS</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon @click="showFilter()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-dialog v-model="getStudentFilterStatus"  max-width="900" height='600'>
          <v-card>
            <v-card-title class="headline bbe-blue justify-center white--text">
              Filter
            </v-card-title>
            <v-card-text>
              <StudentFilter/>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-menu offset-y offset-yorigin="center center">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item  @click="editItem">
              <v-list-item-title>NEW STUDENT</v-list-item-title>
            </v-list-item>
            <v-list-item  :to="'/EnableTest'">
              <v-list-item-title>ENABLE TEST</v-list-item-title>
            </v-list-item>

            <v-list-item  @click="openCVSDialog">
              <v-list-item-title>UPLOAD EXCEL</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-toolbar>

      <!-- this is for searching filter -->
      <v-card-title>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        ></v-text-field>
      </v-card-title>

      <!-- this is for listing the objects -->
      <v-card class="mx-1 my-1 pa-3">
        <v-data-table
        :headers="headers"
        :items="students"
        sort-by="name"
        :search="search"
        class="pa-1 ma-1"
        >
          <!-- Button for new student -->
          <template v-slot:top>

            <v-dialog v-model="getStudentDialogEdit" persistent>
              <Errors :errors="getErrors" />
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-main class="pt-1">
                  <v-card-text>
                    <v-row >

                      <v-col sm="6">
                        <v-text-field v-model="getEditedStudent.name" label="Name">
                        </v-text-field>
                      </v-col>

                      <v-col sm="6">

                        <v-text-field
                        v-model="getEditedStudent.cedula"
                        label="Cedula"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="">

                      <v-col sm="6">
                        <v-select
                        v-model="getEditedStudent.company_id"
                        :items="getDropdownCompanies"
                        item-text="name"
                        item-value="id"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select Company"
                        chips
                        hint="The company is not mandatory"
                        persistent-hint
                        @change="onChange($event)"
                        ></v-select>
                      </v-col>

                      <!-- <v-col cols="6">
                        <v-text-field
                        v-model="getEditedStudent.response_email_to"
                        label="Report email to"
                        ></v-text-field>
                      </v-col> -->

                        <v-col cols="6">
                         <p style="color:gray;">Report emails</p>
                        <div class="d-inline mx-2" v-for="(value, key, index) in companyEmails" :key="index">
                        {{value}} 
                        </div>
                      </v-col>

                    </v-row>

                    <v-row>

                      <v-col cols="6">
                        <v-text-field
                        v-model="getEditedStudent.email"
                        :rules="emailRules"
                        label="Email" >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-menu
                        v-model="menuDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="getEditedStudent.programmed_date"
                            label="Programmed Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                          v-model="getEditedStudent.programmed_date"
                          @input="menuDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-select
                        v-model="getEditedStudent.language_ids"
                        :items="getDropdownLanguages"
                        item-text="name"
                        item-value="id"
                        label="Select Languages"
                        :menu-props="{ top: true, offsetY: true }"
                        multiple
                        chips
                        hint="Pick the languages the student is going to do test"
                        persistent-hint
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-main>
                <v-card-actions class="d-flex justify-center">
                  <v-btn class="bbe-blue" dark text @click="save">
                    Save
                  </v-btn>
                  <v-btn class="bbe-orange" dark text @click="close">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- The following dialog is for delete item -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  Student - Delete
                </v-card-title>
                <div class="text--primary text-center ma-2">
                  Are you sure you want to delete this item?
                </div>
                <v-card-actions class="justify-center">

                  <v-btn class="bbe-orange" dark text @click="deleteItemConfirm">
                    DELETE
                  </v-btn>
                  <v-btn class="bbe-blue" dark text @click="closeDelete">
                    CANCEL
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogShow" max-width="800px">
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  Student - Show
                </v-card-title>
                <v-card-text>
                  <v-main class="pa-1">
                    <v-row>
                      <v-col sm="2" class="font-weight-bold">
                        Name
                      </v-col>

                      <v-col sm="4">
                        {{ getEditedStudent.name }}
                      </v-col>


                      <v-col sm="2" class="font-weight-bold">
                        Cedula
                      </v-col>

                      <v-col sm="4">
                        {{ getEditedStudent.cedula }}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="2" class="font-weight-bold">
                        Email
                      </v-col>

                      <v-col cols="12" sm="4">
                        {{ getEditedStudent.email }}
                      </v-col>

                      <v-col cols="12" sm="2" class="font-weight-bold">
                        Report Email To
                      </v-col>

                      <v-col cols="12" sm="4">
                        {{ getEditedStudent.response_email_to }}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col sm="2" class="font-weight-bold">
                        Company
                      </v-col>

                      <v-col sm="4">
                        {{ getEditedStudent | setCompanyName }}
                      </v-col>

                      <v-col cols="12" sm="2" class="font-weight-bold">
                        Programmed Date
                      </v-col>

                      <v-col cols="12" sm="4">
                        {{ getEditedStudent.programmed_date }}
                      </v-col>
                    </v-row>

                    <v-card-title class="headline justify-center">
                      Languages
                    </v-card-title>

                    <v-row>
                      <v-chip
                      class="ma-2"
                      v-for="language in getEditedStudent.languages"
                      :key="language.name"
                      >{{ language.name | capitalize }}</v-chip>
                    </v-row>
                  </v-main>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>

          <!-- The following are the icons for the CRUD actions -->
          <template v-slot:[`item.actions`]="{ item }" class="justify-around">
             <div class="td-actions" style="min-width:120px">
              <v-icon medium class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon medium @click="showItem(item)">
                mdi-eye
              </v-icon>
              <v-icon medium @click="deleteItem(item)">
                mdi-delete
              </v-icon>
             </div>
          </template>

          <!-- Re send emails -->
          <template v-slot:[`item.reports`]="{ item }" class="justify-center">
            <div>
            <v-btn class="bbe-blue text-white" dark text @click="reSendMails(item.id)">
              ReSend email
            <v-icon medium class="ml-2 mx-auto" small color="white">
              mdi-email-send-outline
            </v-icon>
            </v-btn>
            </div>
          </template>

        </v-data-table>
      </v-card>
    </v-card>
  </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import Errors from "@/components/Errors";
import Results from "@/components/Results";
import StudentFilter from "@/components/students/StudentFilter";
import UploadCSV from "@/components/students/UploadCSV";
import Loading from "@/components/Loading";

export default {
  components: {
    Errors,
    Loading,
    Results,
    StudentFilter,
    UploadCSV
  },
  computed: {
    ...mapGetters([
      "getEditedStudent",
      "getErrors",
      "getDropdownLanguages",
      "getDropdownCompanies",
      "getStudents",
      "getStudentDialogEdit",
      "getStudentFilterStatus",
      "getResults",
      "isInfoLoading"
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Student" : "Edit Student";
    },
  },
  created() {
    this.initializeStudent();
    this.loadLanguagesDropdown();
    this.loadCompaniesDropdown();
  },
  data() {
    return {
      dialogDelete: false,
      dialogShow: false,
      editedIndex: -1,
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Resend Reports", value: "reports" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      menuDate: false,
      search: "",
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+/.test(v) || 'Email must be valid',
      ],
      companyEmails:[]
    };
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    setCompanyName: function(item){
      if (item.company === undefined || item.company === null) {
        return ""
      }else {
        return item.company.name
      }
    }
  },
  methods: {
    ...mapActions([
      "createStudent",
      "deleteStudent",
      "initializeStudent",
      "loadLanguagesDropdown",
      "loadCompaniesDropdown",
      "setEditedStudent",
      "updateStudent",
      "uploadStudentsCVS",
      "setStudentDialogEditStatus",
      "setStudentFilterStatus",
      "cleanStudent",
      "reSendEmailStudent"
    ]),
    close() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.cleanStudent();
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
      this.companyEmails = []
    },
    closeDelete() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.students.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deleteStudent(this.editedItem);
      this.closeDelete();
    }, 
    editItem(item) {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.editedIndex = this.getStudents.indexOf(item);
      if (item.address === undefined) {
        this.setEditedStudent(this.getEditedStudent);
      } else {
        this.setEditedStudent(item);
      }
      this.setStudentDialogEditStatus();
      this.onChange(this.getEditedStudent.company_id)
    },
    openCVSDialog(){
      this.uploadStudentsCVS();
    },
    save() {
      // if index is different to -1 means the record was modified
      if (this.editedIndex > -1) {
        this.updateStudent(this.getEditedStudent);
        this.companyEmails = []
      } else {
        // this means is a new record
        this.createStudent(this.getEditedStudent);
        this.companyEmails = []
      }
    },
    showItem(item) {
      this.showedIndex = this.students.indexOf(item);
      this.setEditedStudent(item);
      this.dialogShow = true;
    },
    showFilter(){
      this.setStudentFilterStatus()
    },

    onChange(event) {
      let auxEmail = this.getDropdownCompanies.find(element => element.id == event)
       this.companyEmails = auxEmail.emails
    },
    reSendMails(id){
      this.reSendEmailStudent(id);
      // alert(`resend email to user with id ',${id}`)
    }
    
  },
  name: "Student",
  props: {
    students: {
      type: Array,
      required: true
    }
  },
  watch: {
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
};

</script>
