<template>
  <v-card-text>
    <v-container>
      <!-- <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-radio-group v-model="filterParams.graded_status" row>
            <v-radio label="Graded" color="indigo darken-3" value="1"></v-radio>
            <v-radio label="No graded" color="indigo darken-3" value="0">
            </v-radio>
            <v-radio label="Any" color="indigo darken-3" value="any"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12" v-if="getIsUserAdmin">
          <v-select
          v-model="filterParams.company_id"
          :items="getDropdownCompanies"
          item-text="name"
          item-value="id"
          :menu-props="{ maxHeight: '400' }"
          label="Select Company"
          clearable
          chips
          solo
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" v-if="getIsUserAdmin">
          <v-select
          v-model="filterParams.language_id"
          :items="getDropdownLanguages"
          item-text="name"
          item-value="id"
          label="Select Languages"
          :menu-props="{ top: true, offsetY: true }"
          clearable
          chips
          solo
          ></v-select>
        </v-col>
      </v-row>






      <v-row>
        <v-col cols="12" align="right">
          <v-btn
          elevation="2"
          large
          x-large
          dark
          color="bbe-blue"
          @click="filter"
          >Filter</v-btn>
        </v-col>
      </v-row>




    </v-container>
  </v-card-text>
</template>





<script>

import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "getDropdownCompanies",
      "getDropdownLanguages",
      "getIsUserAdmin",
      "getTeachers",
    ])
  },
  created() {
    this.loadCompaniesDropdown();
    this.loadLanguagesDropdown();
  },
  data() {
    return {
      filterParams: {
        company_id: "",
        language_id: "",
      }
    }
  },
  methods: {
    ...mapActions([
      "filterResponses",
      "setShowFilterActive",
      "setUnsentResponsesFilterActive",
      "filterUnsentResponses",
      "loadCompaniesDropdown",
      "loadLanguagesDropdown"
    ]),
    filter() {
      this.filterUnsentResponses(this.filterParams)
      this.setUnsentResponsesFilterActive()
    }
  },
  name: 'UnsentResponsesFilter'
}
</script>
