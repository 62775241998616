<template>
  <div class="">
    <Loading v-if="isInfoLoading"/>
    <v-card v-else class="mx-3 my-5 pa-1">
      <Errors :errors="getErrors" />
      <Results :results="getResults" />

      <v-toolbar color="bbe-blue" dark >

        <v-toolbar-title>COMPANIES</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-menu offset-y offset-yorigin="center center">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item  @click="editItem">
              <v-list-item-title>NEW COMPANY</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-toolbar>

      <!-- this is for searching filter -->
      <v-card-title>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        ></v-text-field>
      </v-card-title>

      <!-- this is for listing the objects -->
      <v-card class="mx-1 my-1 pa-3">
        <v-data-table
        :headers="headers"
        :items="companies"
        sort-by="name"
        :search="search"
        class="pa-1 ma-1"
        >
          <!-- Button for new student -->
          <template v-slot:top>

            <v-dialog v-model="getCompanyDialogEdit" persistent>
              <Errors :errors="getErrors" />
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-main class="pt-1">
                  <v-card-text>
                    <v-row>
                      <v-col sm="6">
                        <v-text-field v-model="editedItem.name" label="Name">
                        </v-text-field>
                      </v-col>
                      <v-col sm="6">
                        <v-text-field v-model="editedItem.nit" label="Nit">
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6">
                        <v-text-field v-model="editedItem.address" label="Address">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                        v-model="editedItem.city"
                        label="City"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                        v-model="editedItem.contact_person"
                        label="Contact Person"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="editedItem.email" label="Email">
                        </v-text-field>
                      </v-col>
                      <v-col cols="10" sm="8">
                        <v-text-field type="email" v-model="emails" @blur="validateEmail" label="Agree new email" placeholder='Agree a new email associated for the company'>
                        </v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" >
                        <v-btn class="bbe-orange" dark text @click="agregateEmails()">Agregate Email<v-icon class="ml-2" color="white" dense>mdi-email-plus</v-icon> </v-btn>
                      </v-col>
                      <v-col cols="12">
                        <p style="color:gray"> Associated Emails</p>
                        <div class="d-inline mx-2" v-for="(value, key, index) in editedItem.emails" :key="index">
                        {{value}} 
                       <v-icon class=" d-inline" color="indigo" dense @click="deleteMail(key)">mdi-email-remove</v-icon> 
                        </div>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6">
                        <v-text-field v-model="editedItem.cell_phone" label="Cell Phone">
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                         v-model="editedItem.access_code"
                         label="Access code"
                         hint="Access code for guest users"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                        v-model="editedItem.notes"
                        label="Notes"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-main>
                <v-card-actions class="d-flex justify-center">
                  <v-btn class="bbe-blue" dark text @click="save">
                    Save
                  </v-btn>
                  <v-btn class="bbe-orange" dark text @click="close">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- The following dialog is for delete item -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  Company - Delete
                </v-card-title>
                <div class="text--primary text-center ma-2">
                  Are you sure you want to delete this item?
                </div>
                <v-card-actions class="justify-center">

                  <v-btn class="bbe-orange" dark text @click="deleteItemConfirm">
                    DELETE
                  </v-btn>
                  <v-btn class="bbe-blue" dark text @click="closeDelete">
                    CANCEL
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogShow" max-width="800px">
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  {{ editedItem.name }}
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="2" class="font-weight-bold">
                        NIT
                      </v-col>

                      <v-col cols="12" sm="4">
                        {{ editedItem.nit }}
                      </v-col>

                      <v-col cols="12" sm="2" class="font-weight-bold">
                        Ciudad
                      </v-col>

                      <v-col cols="12" sm="4">
                        {{ editedItem.city }}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="2" class="font-weight-bold">
                        Contact Person
                      </v-col>

                      <v-col cols="12" sm="4">
                        {{ editedItem.contact_person }}
                      </v-col>

                      <v-col cols="12" sm="2" class="font-weight-bold">
                        Email
                      </v-col>

                      <v-col cols="12" sm="4">
                        {{ editedItem.email }}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="2" class="font-weight-bold">
                        Cell Phone
                      </v-col>

                      <v-col cols="12" sm="4">
                        {{ editedItem.cell_phone }}
                      </v-col>

                      <v-col cols="12" sm="2" class="font-weight-bold">
                        Address
                      </v-col>

                      <v-col cols="12" sm="4">
                        {{ editedItem.address }}
                      </v-col>
                    </v-row>

                    <v-card-title class="headline justify-center">
                      Notes
                    </v-card-title>

                    <v-row>
                      <v-col cols="12" sm="12">
                        {{ editedItem.notes }}
                      </v-col>

                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>

          <!-- The following are the icons for the CRUD actions -->
          <template v-slot:item.actions="{ item }" class="justify-around">
            <v-icon medium class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="showItem(item)">
              mdi-eye
            </v-icon>
            <v-icon medium @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>

        </v-data-table>
      </v-card>
    </v-card>
  </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import Errors from "@/components/Errors";
import Results from "@/components/Results";
import Loading from "@/components/Loading";


export default {
  components: {
    Errors,
    Loading,
    Results,
  },
  computed: {
    ...mapGetters([
      "getErrors",
      "getDropdownLanguages",
      "getDropdownCompanies",
      "getCompanies",
      "getCompanyDialogEdit",
      "getCompanyFilterStatus",
      "getResults",
      "isInfoLoading"
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Company" : "Edit Company";
    }
  },
  created() {
    this.initializeCompany();
    this.loadLanguagesDropdown();
    this.loadCompaniesDropdown();
  },
  data() {
    return {
      dialogDelete: false,
      dialogShow: false,
      editedIndex: -1,
      editedItem: {
        id: "",
        address: "",
        cell_phone: "",
        city: "",
        contact_person: "",
        email: "",
        name: "",
        nit: "",
        notes: "",
        phone: "",
        access_code:"",
        emails:{
          type:Array,
        }
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      menuDate: false,
      search: "",
      emails:"",
      emailsArray:[],
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+/.test(v) || 'Email must be valid',
      ],
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    setCompanyName: function(item){
      if (item.company === undefined || item.company === null) {
        return ""
      }else {
        return item.company.name
      }
    }
  },
  methods: {
    ...mapActions([
      "createCompany",
      "deleteCompany",
      "initializeCompany",
      "loadLanguagesDropdown",
      "loadCompaniesDropdown",
      "setEditedCompany",
      "updateCompany",
      "uploadCompaniesCVS",
      "setCompanyDialogEditStatus",
      "setCompanyFilterStatus"
    ]),
    close() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.setCompanyDialogEditStatus();
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
     this.emailsArray = []
    },
    closeDelete() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.companies.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deleteCompany(this.editedItem);
      this.closeDelete();
    },
    editItem(item) {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.editedIndex = this.companies.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.emails ? this.emailsArray = this.editedItem.emails : ''
      this.setCompanyDialogEditStatus();
    },
    openCVSDialog(){
      this.uploadCompaniesCVS();
    },
    save() {
      // if index is different to -1 means the record was modified
      if (this.editedIndex > -1) {
        this.updateCompany(this.editedItem);
        this.emailsArray = []
      } else {
        // this means is a new record
        this.createCompany(this.editedItem);
        this.emailsArray = []
      }
    },
    showItem(item) { 
      this.showedIndex = this.companies.indexOf(item);
      this.setEditedCompany(item);
      this.editedItem = Object.assign({}, item);
      this.dialogShow = true;
    },
    showFilter(){
      this.setCompanyFilterStatus()
    },
    agregateEmails(){
      if (this.editedIndex > -1) {
        // update
       this.editedItem.emails.includes(this.editedItem.email) ? '': this.editedItem.emails.push(this.editedItem.email)
       this.validateEmail(this.emails) ? this.editedItem.emails.unshift(this.emails) : ''
       this.emails =""
      } else {
        // Create
        if(this.editedItem.emails === undefined){    
         this.validateEmail(this.emails) ?this.emailsArray.push(this.emails) : ''
          this.editedItem.emails = this.emailsArray
          // this.emailsArray = []
        }else{
           this.validateEmail(this.emails) ? this.editedItem.emails.push(this.emails) : ''
        }
         this.emails =""
        // this means is a new email in new compaines
      }  
    },
    validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    },
    deleteMail(index){ 
      
      if (this.editedIndex > -1) {
        console.log('eliminar index en posición', index)
        this.editedItem.emails.splice(index,1)
        this.emails =""
      } else { 
        console.log('eliminar index en posición', index)
        this.emailsArray = this.editedItem.emails
        this.emailsArray.splice(index,1)
        this.editedItem.emails = this.emailsArray
        this.emails =" "
      }
      
    }
  },
  name: "Company",
  props: {
    companies: {
      type: Array,
      required: true
    }
  },
  watch: {
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
};

</script>
