import WaveSurfer from "wavesurfer.js";
import MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.js";
WaveSurfer.microphone = MicrophonePlugin;
import "videojs-wavesurfer/dist/css/videojs.wavesurfer.css";
import Wavesurfer from "videojs-wavesurfer/dist/videojs.wavesurfer.js";
console.log(Wavesurfer);
import "video.js/dist/video-js.min.css";
import videojs from "video.js";
import RecordRTC from "recordrtc";
import "videojs-record/dist/css/videojs.record.css";
import Record from "videojs-record/dist/videojs.record.js";
import api from "@/api";

const actions = {
  async startsRecording({ commit }) {
    commit("STARTS_RECORDING");
  },

  async startsRecorder({ commit }, div) {
    commit("STARTS_RECORDER", div);
  },

  async submitVideo({ commit, dispatch }) {
    await dispatch("setFromData").then(formData => {
      commit("SHOWS_SAVING_DIALOG");
      api
        .createAnswer(formData)
        .then(response => {
          console.log(response);
          dispatch("startsRecorder", "recorderView");
          commit("SHOWS_SAVING_BUTTON");
        })
        .catch(error => {
          commit("ADD_ERRORS", error);
        });
    });
  },

  playRecord() {
    state.player.play();
  },

  loadNextQuestion({ commit, dispatch }) {
    dispatch("checkStep");
    commit("SHOWS_SAVING_DIALOG");
    commit("SHOWS_SAVING_BUTTON");
  },

  setFromData({ commit }) {
    commit("SET_FROM_DATA");
    return this.getters.getFormData;
  },

  stopRecording({ commit, dispatch }) {
    commit("STOP_RECORDING");
    dispatch("submitVideo");
  }
};

const getters = {
  getContinueQuestion: state => state.continueQuestion,
  getIsRecording: state => state.isRecording,
  getStopRecording: state => state.stopRecording,
  getPlayer: state => state.player,
  getRecordSaved: state => state.recordSaved,
  getFormData: state => state.formData
};

const mutations = {
  SET_FROM_DATA: function(state) {
    let data = state.player.recordedData;
    let questionId = this.getters.getQuestionsToAnswer[this.getters.getPosition]
      .id;
    let responseId = this.getters.getResponse.id;
    state.formData.append("answer[audio_file]", data, data.name);
    state.formData.append("answer[response_id]", responseId);
    state.formData.append("answer[question_id]", questionId);
    state.player.record().reset();
  },
  STARTS_RECORDER: (state, div) => {
    state.player = videojs(div, state.options, () => {
      // print version information at startup
      let msg =
        "Using video.js " +
        videojs.VERSION +
        " with videojs-record " +
        videojs.getPluginVersion("record") +
        ", videojs-wavesurfer " +
        videojs.getPluginVersion("wavesurfer") +
        ", wavesurfer.js " +
        WaveSurfer.VERSION +
        " and recordrtc " +
        RecordRTC.version +
        " record " +
        Record;
      videojs.log(msg);
    });
    state.isRecording = true;
    state.stopRecording = false;
  },
  STARTS_RECORDING: state => {
    state.isRecording = false;
    state.stopRecording = true;
    state.player.record().getDevice();
  },
  STOP_RECORDING: state => {
    state.stopRecording = !state.stopRecording;
  },
  SHOWS_SAVING_DIALOG: state => {
    state.continueQuestion = !state.continueQuestion;
  },
  SHOWS_SAVING_DIALOG_INIT: state => {
    state.continueQuestion = false;
  },
  SHOWS_SAVING_BUTTON: state => {
    state.recordSaved = !state.recordSaved;
  },
  SHOWS_SAVING_BUTTON_INIT: state => {
    state.recordSaved = false;
  }
};

const state = {
  continueQuestion: false,
  recordSaved: false,
  player: "",
  stopRecording: false,
  formData: new FormData(),
  isRecording: true,
  options: {
    autoplay: false,
    controls: true,
    bigPlayButton: false,
    bigRecordButton: false,
    height: 300,
    fluid: false,
    responsive: true,
    controlBar: {
      // hide fullscreen and volume controls
      deviceButton: false,
      cameraButton: false,
      pipToggle: false,
      fullscreenToggle: false,
      recordIndicator: false,
      recordToggle: false,
      volumePanel: false
    },
    plugins: {
      wavesurfer: {
        audioRate: 1,
        backgroundColor: "#ddd",
        backend: "WebAudio",
        bigPlayButton: true,
        cursorWidth: 1,
        debug: true,
        displayMilliseconds: false,
        hideScrollbar: true,
        interact: false,
        progressColor: "#4687E0",
        waveColor: "#021a42",
        plugins: [
          // enable microphone plugin
          WaveSurfer.microphone.create({
            bigPlayButton: false,
            bufferSize: 4096,
            numberOfInputChannels: 2,
            numberOfOutputChannels: 2,
            constraints: {
              video: false,
              audio: true
            }
          })
        ]
      },
      record: {
        bigPlayButton: false,
        audio: true,
        video: false,
        maxLength: 180,
        displayMilliseconds: false,
        debug: true
      }
    }
  }
};

export default {
  actions,
  getters,
  mutations,
  state
};
