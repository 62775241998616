import api from "@/api";

const actions = {
  async createCompany({ commit, dispatch }, company) {
    await api
      .createCompany(company)
      .then(response => {
        commit("INITIALIZE_ERRORS");
        commit("ADD_TO_COMPANIES", response.data);
        commit("ADD_RESULTS", response.data);
        dispatch("setCompanyDialogEditStatus");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async deleteCompany({ commit }, company) {
    await api
      .deleteCompany(company)
      .then(response => {
        commit("REMOVE_FROM_COMPANIES", response.data);
        commit("ADD_RESULTS", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async loadCompanies({ commit, dispatch }) {
    await api
      .loadCompanies()
      .then(response => {
        commit("LOAD_COMPANIES", response.data);
        dispatch("changeIsLoadingState");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async initializeCompany({ commit, dispatch }) {
    commit("INITIALIZE_RESULTS");
    commit("INITIALIZE_ERRORS");
    commit("INITIALIZE_COMPANY");
    dispatch("changeIsLoadingState");
  },
  async loadCompaniesDropdown({ commit }) {
    await api.loadCompaniesDropdown().then(response => {
      commit("SET_COMPANIES_DROPDOWN", response.data);
    });
  },
  async updateCompany({ commit, dispatch }, company) {
    await api
      .updateCompany(company)
      .then(response => {
        commit("UPDATE_COMPANY", response.data);
        commit("UPDATE_DROPDOWN",response.data);
        commit("ADD_RESULTS", response.data);
        dispatch("setCompanyDialogEditStatus");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  setEditedCompany({ commit }, selectedCompany) {
    commit("SET_EDITED_COMPANY", selectedCompany);
  },

  setCompanyDialogEditStatus({ commit }) {
    commit("SET_COMPANY_DIALOG_EDIT_STATUS");
  }
};

const getters = {
  getCompanies: state => state.companies,
  getCompanyDialogEdit: state => state.companyDialogEdit,
  getDropdownCompanies: state => state.dropdownCompanies,
  getEditedCompany: state => state.editedCompany
};

const mutations = {
  ADD_TO_COMPANIES: (state, company) => {
    state.companies.push(company.company);
  },
  INITIALIZE_COMPANY: state => {
    state.companies = [];
  },
  LOAD_COMPANIES: (state, companies) => {
    for (var i = 0; i < companies.length; i++) {
      state.companies.push(companies[i]);
    }
  },
  REMOVE_FROM_COMPANIES: (state, payload) => {
    let companyIndex = state.companies.findIndex(
      x => x.id === payload.company.id
    );
    state.companies.splice(companyIndex, 1);
  },
  SET_COMPANIES_DROPDOWN: (state, companies) => {
    for (var i = 0; i < companies.length; i++) {
      state.dropdownCompanies.push(companies[i]);
    }
  },
  UPDATE_COMPANY: (state, company) => {
    let companies = state.companies;
    let company_to_replace = state.companies.findIndex(
      x => x.id == company.company.id
    );
    companies[company_to_replace] = company.company;
    state.companies = [];
    for (var i = 0; i < companies.length; i++) {
      state.companies.push(companies[i]);
    }
    state.editedCompany = state.defaultCompany;
  },

  UPDATE_DROPDOWN:(state,company) =>{
    let dropdownCompanies = state.dropdownCompanies;
    let company_to_replace = state.dropdownCompanies.findIndex(x => x.id == company.company.id);
    dropdownCompanies[company_to_replace] = company.company
    state.dropdownCompanies = [];
    for (var i = 0; i < dropdownCompanies.length; i++) {
      state.dropdownCompanies.push(dropdownCompanies[i]);
    }
  },

  SET_EDITED_COMPANY: (state, selectedCompany) => {
    state.editedCompany.id = selectedCompany.id;
    state.editedCompany.address = selectedCompany.address;
    state.editedCompany.cell_phone = selectedCompany.cell_phone;
    state.editedCompany.city = selectedCompany.city;
    state.editedCompany.contact_person = selectedCompany.contact_person;
    state.editedCompany.email = selectedCompany.email;
    state.editedCompany.name = selectedCompany.name;
    state.editedCompany.nit = selectedCompany.nit;
    state.editedCompany.notes = selectedCompany.notes;
    state.editedCompany.phone = selectedCompany.phone;
  },

  SET_COMPANY_DIALOG_EDIT_STATUS: state => {
    state.companyDialogEdit = !state.companyDialogEdit;
  }
};

const state = {
  defaultCompany: {
    id: "",
    address: "",
    cell_phone: "",
    city: "",
    contact_person: "",
    email: "",
    name: "",
    nit: "",
    notes: "",
    phone: "",
    access_code:""
  },
  companyDialogEdit: false,
  companies: [],
  dropdownCompanies: [],
  editedCompany: {
    id: "",
    address: "",
    cell_phone: "",
    city: "",
    contact_person: "",
    email: "",
    name: "",
    nit: "",
    notes: "",
    phone: "",
    access_code:""
  }
};

export default {
  actions,
  getters,
  mutations,
  state
};
