<template>
  <div class="">
    <Loading v-if="isInfoLoading"/>
    <v-card v-else class="mx-3 my-5 pa-1">
      <Errors :errors="getErrors" />
      <Results :results="getResults" />
    <v-tabs
      color="#00005B"
      centered
      fixed-tabs
    >
      <v-tab>PENDING REPORTS</v-tab>
      <v-tab>SENT REPORTS</v-tab>

      <v-tab-item>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-toolbar color="bbe-blue" dark >
                <v-toolbar-title>PENDING REPORTS</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="setUnsentResponsesFilterActive()">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <v-dialog v-model="getUnsentResponsesFilterActive"  max-width="900" height='600'>
                  <v-card>
                    <v-card-title class="headline bbe-blue justify-center white--text">
                      Filter
                    </v-card-title>
                    <v-card-text>
                      <UnsentResponsesFilter />
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-toolbar>

               <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                >
                </v-text-field>
              </v-card-title>

              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="getUnsentResponses"
                sort-by="name"
                :search="search"
                class="elevation-1"
                show-select
              >
                <template v-slot:item.created_at="{ item }">
                      {{ item | setAnsweredAt }}
                </template>
                <!-- The following are the icons for the CRUD actions -->
                <template v-slot:item.actions="{ item }" class="justify-around">
                  <v-icon medium @click="hideResponse(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>

              <v-row class="">
                <v-col cols="12" class="d-flex justify-end">
                  <v-btn class="bbe-blue" dark @click="sendReports()">
                    SEND REPORTS
                  </v-btn>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-container>

      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <v-row>
            <v-col>
             <v-toolbar color="bbe-blue" dark >
                <v-toolbar-title>SENT REPORTS</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="setSentResponsesFilterActive()">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <v-dialog v-model="getSentResponsesFilterActive"  max-width="900" height='600'>
                  <v-card>
                    <v-card-title class="headline bbe-blue justify-center white--text">
                      Filter
                    </v-card-title>
                    <v-card-text>
                      <SentResponsesFilter />
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-toolbar>

               <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                >
                </v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedResend"
                :headers="headers"
                :items="getSentResponses"
                sort-by="name"
                :search="search"
                class="elevation-1"
                show-select
              >
                  <template v-slot:item.created_at="{ item }">
                        {{ item | setAnsweredAt }}
                  </template>
                <!-- The following are the icons for the CRUD actions -->
                  <template v-slot:item.actions="{ item }" class="justify-around">
                    <v-icon medium @click="hideResponse(item)">
                      mdi-delete
                    </v-icon>
                  </template>
              </v-data-table>

              <v-row class="">
                <v-col cols="12" class="d-flex justify-end">
                  <v-btn class="bbe-blue" dark @click="reSendReports()">
                    RESEND REPORTS
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </v-card>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import Errors from "@/components/Errors";
import Results from "@/components/Results";
import Loading from "@/components/Loading";
import UnsentResponsesFilter from "@/components/responses/UnsentResponsesFilter";
import SentResponsesFilter from "@/components/responses/SentResponsesFilter";

export default {
  created() {
    this.initializeUnsentResponses();
    this.initializeSentResponses();
  },
  components: {
    Errors,
    Loading,
    Results,
    UnsentResponsesFilter,
    SentResponsesFilter
  },
  computed: {
    ...mapGetters([
      "getErrors",
      "getTeachers",
      "getResults",
      "isInfoLoading",
      "getUnsentResponses",
      "getSentResponses",
      "getUnsentResponsesFilterActive",
      "getSentResponsesFilterActive",
    ]),
    formTitle() {
      return this.getEditedResponse === -1 ? "New Response" : "Edit Response";
    }
  },
  data(){
    return {
      headers: [
        { text: "Student", value: "student.name" },
        { text: "Language", value: "language.name" },
        { text: "Company", value: "student.company.name" },
        { text: "Answered at ", value: "created_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      search: "",
      selected: [],
      selectedResend:[],
      items: [
          'web', 'shopping', 'videos', 'images', 'news',
        ],
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }
  },
  methods: {
    ...mapActions([
      "hideResponse",
      "initializeUnsentResponses",
      "initializeSentResponses",
      "sendsResponses",
      "setUnsentResponsesFilterActive",
      "setSentResponsesFilterActive",
    ]),
    sendReports() {
      this.sendsResponses(this.selected)
      this.selectedTeacher = ""
    },
    reSendReports(){
      this.sendsResponses(this.selectedResend)
      this.selectedTeacher = ""
      this.selectedResend = []
    }
  },
  filters:{
    setAnsweredAt: function(item) {
      let newDate = item.created_at.split("T")
      return newDate[0]
    },
  },
  name: "UnsentResponses",

}

</script>
