<template>
  <v-app id="app">
    <Main v-if="getSignedIn"/>
    <Signin v-if="!getSignedIn"/>
  </v-app>
</template>

<script>
import Main from "@/views/Main";
import Signin from '@/views/SignIn'

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Main,
    Signin
  },
  computed: {
    ...mapGetters([
      "getSignedIn",
    ])
  },
  data: () => ({
    drawer: false,
    group: null,
    icons: [
      {
        icon: 'mdi-facebook',
        url: 'https://www.facebook.com/bbelanguages'
      },
      {
        icon: 'mdi-twitter',
        url: 'https://twitter.com/bbelanguages'
      },
      {
        icon: 'mdi-linkedin',
        url: 'https://www.linkedin.com/company/bbe-languages'
      },
      {
        icon: 'mdi-instagram',
        url: 'https://www.instagram.com/bbelanguages/'
      }
    ],
  }),
  methods: {
    ...mapActions([
      "signOut",
    ])
  },
  watch: {
    group () {
      this.drawer = false
    },
  },
};
</script>
<style>

 @font-face {
   font-family:"Raleway Bold";
   src: local("Raleway-Bold") url("/assets/Fonts/Raleway-Bold.ttf") format("truetype") ;
 }
 @font-face {
   font-family:"Lato-MediumItalic";
   src: url("/assets/Fonts/Lato-MediumItalic.ttf") format('ttf') /* Safari, Android, iOS */;
 }
 @font-face {
   font-family:"Monserrat-Bold";
   src: url("/assets/Fonts/Monserrat-Bold.ttf") format('ttf') ;
 }
 @font-face {
   font-family:"Raleway-BoldItalic";
   src: url("/assets/Fonts/Raleway-BoldItalic.ttf") format('ttf') ;
 }
 @font-face {
   font-family:"Raleway-Medium";
   src: url("/assets/Fonts/Raleway-Medium.ttf") format('ttf') ;
 }
 @font-face {
   font-family:"Raleway-MediumItalic";
   src: url("/assets/Fonts/Raleway-MediumItalic.ttf") format('ttf') ;
 }
 @font-face {
   font-family:"Raleway-SemiBold";
   src: url("/assets/Fonts/Raleway-SemiBold.ttf") format('ttf') ;
 }
 @font-face {
   font-family:"Raleway-SemiBoldItalic";
   src: url("/assets/Fonts/Raleway-SemiBoldItalic.ttf") format('ttf') ;
 }
</style>
