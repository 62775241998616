import api from "@/api";
import router from "@/router/";

const actions = {
  checkStep({ commit, dispatch }) {
    dispatch("setNewPosition");
    if (this.getters.getQuestionsToAnswer[this.getters.getPosition]) {
      dispatch("setStep");
    } else {
      commit("SHOWS_SAVING_DIALOG");
      commit("SHOWS_SAVING_BUTTON");
      commit("SET_QUESTIONARY_OFF");
    }
  },

  setStep({ commit, dispatch }) {
    if (state.questionsToAnswer.length !== 0) {
      dispatch(
        "setQuestionTitle",
        this.getters.getQuestionsToAnswer[this.getters.getPosition].title
      );
      dispatch(
        "setQuestionLevel",
        this.getters.getQuestionsToAnswer[this.getters.getPosition].level
      );
      dispatch(
        "setQuestionId",
        this.getters.getQuestionsToAnswer[this.getters.getPosition].id
      );
      commit("HIDES_ACTIVE_EXAM", true);
      commit("SET_LANGUAGE",true)
    } else {
      commit("HIDES_ACTIVE_EXAM", false);
      commit("SET_LANGUAGE", false)
    }
  },

  initializeQuestionary({ commit, dispatch }) {
    commit("SHOWS_SAVING_DIALOG_INIT");
    commit("SHOWS_SAVING_BUTTON_INIT");
    commit("RESET_QUESTIONS");
    commit("INITIALIZE_RESULTS");
    commit("INITIALIZE_ERRORS");
    dispatch("generateResponse", state.languageId);
  },

  statusLanguages({dispatch},studentLanguages){
    let languages =studentLanguages;
    for( let i = 0; i <= languages.length - 1 ; i++){
      let id = languages[i].id
      dispatch("setLanguageStatus",id)
    }
  },

  async generateResponse({ commit, dispatch }, languageId) {
    await api
      .generateResponse(languageId)
      .then(response => {
        commit("GENERATE_RESPONSE", response.data);
        dispatch("setStep");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  setStudentLanguage({ commit }, languageId) {
    commit("SET_STUDENT_LANGUAGE", languageId);
  },

  setNewPosition({ commit }) {
    commit("SET_NEW_POSITION");
  },

  setQuestionLevel({ commit }, level) {
    commit("SET_QUESTION_LEVEL", level);
  },

  setQuestionTitle({ commit }, title) {
    commit("SET_QUESTION_TITLE", title);
  },

  setQuestionId({ commit }, id) {
    commit("SET_QUESTION_ID", id);
  },

  setLanguageStatus({dispatch}, id){
    dispatch("generateResponse",id)
  },

  goTest({commit},finish){ 
    commit("GO_TEST",finish);
  },
  goNextTest({commit},finish){
    commit("GO_NEXT_TEST",finish)
  },
  goHome(){
    router.push("/home")
  }
};

const getters = {
  getExamActive: state => state.examActive,
  getQuestionsToAnswer: state => state.questionsToAnswer,
  getQuestionLevel: state => state.questionLevel,
  getQuestionTitle: state => state.questionTitle,
  getQuestionId: state => state.questionId,
  getPosition: state => state.position,
  getResponse: state => state.response,
  getLanguageStatus: state => state.languageStatus,
  getTest: state => state.test
};

const mutations = {
  SET_STUDENT_LANGUAGE: (state, languageId) => {
    state.languageId = languageId;
    localStorage.languageId = languageId;
    router.push("/questionary");
  },

  SET_QUESTION_LEVEL: (state, level) => {
    state.questionLevel = level;
  },
  SET_QUESTION_TITLE: (state, title) => {
    state.questionTitle = title;
  },
  SET_QUESTION_ID: (state, id) => {
    state.questionID = id;
  },
  SET_NEW_POSITION: state => {
    state.position += 1;
  },

  // INITIALIZE_QUESTIONARY: (state, status) => {
  //   status.state.Student.activeQuestionary = !status.state.Student.activeQuestionary
  // },

  GENERATE_RESPONSE: (state, response) => {
    state.idLanguage = response.language_id
    state.questionsToAnswer = [];
    state.position = 0;
    state.questionTitle = "";
    state.questionLevel = "";
    state.questionId = "";
    state.examActive = true;
    for (var i = 0; i < response.questions.length; i++) {
      let questionAlreadyResponded = false;
      let answers = response.answers;
      if (answers.length !== 0) {
        for (var j = 0; j < answers.length; j++) {
          let answerQuestionId = answers[j].question_id;
          if (answerQuestionId === response.questions[i].id) {
            questionAlreadyResponded = true;
          }
        }
      }
      if (questionAlreadyResponded === false) {
        let question = {};
        question["id"] = response.questions[i].id;
        question["title"] = response.questions[i].title;
        question["level"] = response.questions[i].level;
        state.questionsToAnswer.push(question);
      }
    }
    state.response.id = response.id;
    state.response.student_id = response.student_id;
    state.response.languageId = response.language_id;
  },

  RESET_QUESTIONS: state => {
    state.questionTitle = "";
    state.questionLevel = "";
    state.questionId = "";
  },

  SET_QUESTIONARY_OFF: state => {
    state.examActive = !state.examActive;
  },

  HIDES_ACTIVE_EXAM: (state, newState) => {
    state.examActive = newState;
  },

  SET_LANGUAGE: (state, newState) => {
    const status = {
      languageId :state.idLanguage,
      status:newState
    }
    state.languageStatus.push(status)
  },

  GO_TEST: (state,newState) => { 
    state.test = newState;
    state.languageStatus = []
    localStorage.setItem("test",true);
    router.push("/home");
  },
  GO_NEXT_TEST:(state,newState) => {
    state.test = newState;
    state.languageStatus = []
    localStorage.setItem("test",true);
    router.push("/nextTest");
  }
};

const state = {
  examActive: true,
  questionsToAnswer: [],
  position: 0,
  questionTitle: "",
  questionLevel: "",
  questionId: "",
  response: {
    id: "",
    student_id: "",
    languageId: ""
  },
  languageId: "" || localStorage.languageId,
  showContainer: false,
  studentLanguage: "",
  languageStatus:[{
    languageId:"",
    status : false
  }],
  idLanguage:"",
  test:false || localStorage.test
};

export default {
  actions,
  getters,
  mutations,
  state
};
