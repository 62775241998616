import api from "@/api";

const actions = {
  async createLanguage({ commit, dispatch }, language) {
    await api
      .createLanguage(language)
      .then(response => {
        commit("ADD_TO_LANGUAGES", response.data);
        commit("ADD_RESULTS", response.data);
        dispatch("setLanguageDialogEditStatus");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async deleteLanguage({ commit }, language) {
    await api
      .deleteLanguage(language)
      .then(response => {
        commit("ADD_RESULTS", response.data);
        commit("REMOVE_FROM_LANGUAGES", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async loadLanguages({ commit, dispatch }) {
    await api
      .loadLanguages()
      .then(response => {
        commit("LOAD_LANGUAGES", response.data);
        dispatch("changeIsLoadingState");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async loadLanguagesDropdown({ commit }) {
    await api.loadLanguagesDropdown().then(response => {
      commit("SET_LANGUAGES_DROPDOWN", response.data);
    });
  },
  async loadLanguagesDropdownGuest({ commit }) {
    await api.loadLanguagesDropdownGuest().then(response => {
      commit("SET_LANGUAGES_DROPDOWN", response.data);
    });
  },
  async initializeLanguage({ commit, dispatch }) {
    commit("INITIALIZE_RESULTS");
    commit("INITIALIZE_ERRORS");
    commit("INITIALIZE_LANGUAGE");
    dispatch("changeIsLoadingState");
  },
  async updateLanguage({ commit, dispatch }, language) {
    await api
      .updateLanguage(language)
      .then(response => {
        commit("UPDATE_LANGUAGE", response.data);
        commit("ADD_RESULTS", response.data);
        dispatch("setLanguageDialogEditStatus");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  setLanguageDialogEditStatus({ commit }) {
    commit("SET_LANGUAGE_DIALOG_EDIT_STATUS");
  }
};

const getters = {
  getLanguages: state => state.languages,
  getDropdownLanguages: state => state.dropdownLanguages,
  getLanguageDialogEdit: state => state.languageDialogEdit
};

const mutations = {
  ADD_TO_LANGUAGES: (state, language) => {
    state.languages.push(language.language);
  },
  INITIALIZE_LANGUAGE: state => {
    state.languages = [];
  },
  LOAD_LANGUAGES: (state, languages) => {
    for (var i = 0; i < languages.length; i++) {
      state.languages.push(languages[i]);
    }
  },
  REMOVE_FROM_LANGUAGES: (state, payload) => {
    let languageIndex = state.languages.findIndex(
      x => x.id === payload.language.id
    );
    state.languages.splice(languageIndex, 1);
  },
  SET_LANGUAGES_DROPDOWN: (state, languages) => {
    for (var i = 0; i < languages.length; i++) {
      state.dropdownLanguages.push(languages[i]);
    }
  },
  UPDATE_LANGUAGE: (state, language) => {
    let languages = state.languages;
    let language_to_replace = state.languages.findIndex(
      x => x.id == language.language.id
    );
    languages[language_to_replace] = language.language;
    state.languages = [];
    for (var i = 0; i < languages.length; i++) {
      state.languages.push(languages[i]);
    }
  },
  SET_LANGUAGE_DIALOG_EDIT_STATUS: state => {
    state.languageDialogEdit = !state.languageDialogEdit;
  }
};

const state = {
  languages: [],
  dropdownLanguages: [],
  languageDialogEdit: false
};

export default {
  actions,
  getters,
  mutations,
  state
};
