import api from "@/api";
import axios from "axios";

const actions = {
  async loadTree({ commit }, response) {
    await api
      .getAudios(response.id)
      .then(response => {
        commit("SET_NO_AUDIOS", false);
        if (response.data.length !== 0) {
          var audios = response.data;
          var node = new Object();
          node["id"] = state.last_id + 1;
          state.last_id = state.last_id + 1;
          node["name"] = "Audios";
          node["path"] = `/audio_files/`;
          node["children"] = [];
          var files = actions.createFiles(audios); 
          var new_node = true;
          commit("LOAD_TREE", { node, files, new_node });
        } else {
          commit("SET_NO_AUDIOS", true);
        }
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  async fetchChildren({ commit }, node) {
    await axios
      .get(node.path)
      .then(response => {
        var data = response.data;
        if (data.ok === true) {
          var dirs = actions.createNodes(data.dirs, node);
          var files = actions.createFiles(data.files);
          commit("LOAD_TREE", { node, files, dirs });
        } else {
          alert(`Something went wrong call your admin: ${data.msg}`);
        }
      })
      .catch(error => {
        alert(error);
      });
  },

  createFiles: function(files) { 
    if (files.length !== 0) {
      var files_array = [];
      for (var i = 0; i < files.length; i++) {
        if(files[i].audio_file!= null){
          state.last_id = state.last_id + 1;
        var new_sub_node = new Object();
        new_sub_node["id"] = state.last_id;
        new_sub_node["answerId"] = files[i].answer_id;
        new_sub_node["type"] = "file";
        new_sub_node["name"] = files[i].audio_file.filename;
        new_sub_node[
          "path"
        ] = `/audio_files/${files[i].audio_file.id}/get_audio_blob`;
        new_sub_node["question"] = new Object();
        new_sub_node["question"]["id"] = files[i].question.id;
        new_sub_node["question"]["title"] = files[i].question.title;
        new_sub_node["question"]["level"] = files[i].question.level;

        files_array.push(new_sub_node);
        }
      }
      return files_array;
    }
  },

  async deleteAudio({commit,dispatch},idAnswer){
    await axios.delete(`/answers/${idAnswer}`)
        .then(response => {
          commit("ADD_RESULTS", response.data);
          commit('DELETE_AUDIO');
          dispatch("loadTreeAfterDelete");
        })
  }
};

const getters = { 
  loadItems: state => state.items,
  getLastItem: state => state.last_id,
  getNoAudios: state => state.noAudios,
  loadResults: state => state.result_items
};

const mutations = {
  LOAD_TREE: (state, payload) => { 
    state.items = [];
    var main_node = payload.node;
    var files = payload.files;
    if (files !== undefined) {
      files.map(function(file) {
        main_node.children.push(file);
      });
    }
    if (payload.new_node === true) {
      state.items.push(main_node);
      if (payload.node.id !== 0) {
        state.result_items.push(main_node);
      }
    }
  },
  SET_NO_AUDIOS: (state, newStatus) => {
    state.noAudios = newStatus;
  },

};

const state = {
  items: [],
  last_id: -1,
  result_items: [],
  noAudios: false
};

export default {
  state,
  getters,
  actions,
  mutations
};
