<template class="">
  <div>
    <!-- This is the home card -->
    <div v-if="showForm" class="container-box" fluid>
      <v-card
        elevation="3"
        outlined
        class="mx-auto justify-center align-center rounded-xl"
        max-width="600px"
        align="center"
        style="margin-top:20vh;"
      >
        <v-card-title
          align="center"
          style="background-color:#005cf2; color:white"
          >Completa tu Información!</v-card-title
        >
        <v-card-subtitle class="mt-2"
          >Para continuar hacia tu examen debes completar la siguente
          Información
          </v-card-subtitle
        >
        <v-card-subtitle class="mt-2"
          >Ingresa un nombre y apellido</v-card-subtitle
        >
        <v-col
          cols="12"
          md="10"
          class="fill-height d-flex flex-column justify-center align-center"
        >
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="name"
              :rules="[nameRules, validateLengthInput]"
              label="Ingresa tu nombre y apellido"
              required
            ></v-text-field>
            <v-btn
              rounded
              class="btn-start"
              :disabled="!valid"
              @click="activateHome"
              >Enviar</v-btn
            >
          </v-form>
        </v-col>
      </v-card>
    </div>
    <div v-if="!getTest">
      <div class="container-main-student" v-if="home">
        <div class="container-student">
          <div id="square"></div>
         
          <div class="container-text">
            <div class="container-logo-student">
              <img src="../assets/Recurso 16.png" alt="" height="100px" />
            </div>
            <div class="container-description">
              <h1>
                Bienvenid@ a nuestro <br />
                ambiente de evaluación
              </h1>
              <p>
                Nuestra plataforma está diseñada <br />para evaluar tu habilidad
                de comunicarte <br />de forma oral en el idioma de interés.
              </p>
              <v-btn rounded class="btn-start" @click="activateStepOne"
                >Iniciar</v-btn
              >
            </div>
          </div>
          <div class="container-img">
             <div id="logo-page-student"></div>
          </div>
        </div>
      </div>
      <div class="container-main-student-one-step" v-if="stepOne">
        <div class="header">
          <div class="bbeLogo-step-one"></div>
          <div class="warning-step-one">
            <h1>¡Es clave leer todas las instrucciones!</h1>
            <p>
              Esto permitirá que realices tu examen teniendo las herramientas en
              <br />
              condiciones adecuadas
            </p>
          </div>
        </div>
        <div class="container-one-step" >
          <!-- <div class="container-main-one-step"> -->
            <div class="instructions-one-step">
              <h1>Paso 1</h1>
              <p>
                El dispositivo te pedirá permiso para activar el micrófono.Por
                favor, haz click en "Permitir",
                de lo contrario no podrás reponder las preguntas.
              </p>
              <v-btn class="btn-step-one" rounded @click="activatesSoundTesting">
                Siguiente</v-btn
              >
            </div>
            <div class="image-one-step"></div>
          <!-- </div> -->
        </div>
      </div>
      <div class="container-main-student-two-step" v-if="soundTesting">
        <div class="container-two-step">
          <div>
            <div class="responsive-logo-two-step"></div>
            <div class="bbeLogo-step"></div>
          </div>
          <div class="container-main-two-step">
            <div class="container-image-two-step">
              <div class="logo-step-two"></div>
              <div class="image-step-two">
                <div class="number-step-two"></div>
                <div class="text-step-two"></div>
              </div>
            </div>
            <div class="instructions-two-step">
              <h1>Prueba de sonido</h1>
              <p>
                Para empezar la grabación, deberás hacer clic en el botón rojo y
                repetir la siguiente frase:
              </p>

              <h2>"Esta es una prueba de sonido para BBE Discover"</h2>

              <p>
                Una vez finalices, por favor presiona el botón azul para terminar
                y escucha la grabación. Ésta debe ser clara y sin mucho ruido de
                fondo. Si la grabación no cumple con estos lineamientos, haz los
                ajustes necesarios y vuelve a ingresar.
              </p>
              <v-btn
                class="btn-step-two bbe-orange mb-0"
                width="40%"
                rounded
                @click="showDialogSoundTest"
              >
                Grabar Prueba
              </v-btn>
              <v-btn class="btn-step-two" rounded @click="activatesStepThree">
                Siguiente
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <v-dialog v-model="dialogSoundTest" max-width="500px" persistent>
        <v-card>
          <v-card-title
            class="headline justify-center white--text"
            style="background-color:#005cf2"
          >
            Prueba de sonido
          </v-card-title>
          <div class="text--primary text-center ma-2">
            <RecorderTest />
          </div>
          <p style="color:black; font-size:14px">
            Al dar click en
            <strong> "Mi micrófono funciona correctamente"</strong> <br />
            acepto que mi dispositivo funciona correctamente
          </p>
          <v-card-actions class="justify-center">
            <button class="bbe-orange ma-2 white--text rounded" @click="showDialogSoundTest">
              Mi micrófono funciona correctamente
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="container-main-student-three-step" v-if="stepThree"> 
        <div class="container-two-step">
          <div class="responsive-logo-three-step"></div>
          <div class="bbeLogo-step"></div>
          <div class="container-main-two-step">
            <div class="container-image-two-step">
              <div class="logo-step-two"></div>
              <div class="image-step-three">
                <div class="number-step-three"></div>
                <div class="text-step-three"></div>
              </div>
            </div>
            <div class="instructions-three-step">
              <h1>
                Acuerdo de <br />
                honestidad académica
              </h1>
              <p>
                Como candidato que presenta este examen, me comprometo a responder
                a conciencia y de manera espontánea, sin usar ayudas externas como
                traductores, guiones, personas de apoyo, etc., para obtener un
                resultado mucho más preciso.
              </p>
              <p>
                Me comprometo también a realizar la prueba en un espacio privado,
                sin ruido de fondo y entiendo que esto facilita la calificación
                adecuada de mi prueba.
              </p>
              <p>
                Recuerde que esta es una prueba clasificatoria y nos permitirá
                identificar tus habilidades en producción oral para determinar tu
                nivel global en el idioma.
              </p>
              <v-btn
                class="btn-step-three"
                rounded
                @click="activatesIntegrityPolicy"
              >
                Acepto</v-btn
              >
            </div>
          </div>
        </div>
      </div>

      <div class="container-main-student-four-step" v-if="stepFour">
        <div class="container-two-step">
          <div class="responsive-logo-four-step"></div>
          <div class="bbeLogo-step"></div>
          <div class="container-main-two-step">
            <div class="container-image-four-step">
              <div class="logo-step-two"></div>
              <div class="image-step-four">
                <div class="number-step-four"></div>
                <div class="text-step-four"></div>
              </div>
            </div>
            <div class="instructions-four-step">
              <h1>Recomendaciones</h1>
              <h2>
                ¡Solo tendrás la oportunidad de grabar una (1) vez cada una de las
                respuestas !
              </h2>
              <p>
                1. Lee la pregunta con detenimiento antes de empezar a grabar tu
                respuesta, una vez oprimas el botón de "Grabar" no tendrás otra
                oportunidad para realizar la grabación nuevamente.
              </p>
              <p>
                2. Tendrás máximo tres minutos (3) para grabar la respuesta de
                cada pregunta.
              </p>
              <p>
                3. El sistema sólo guardará los 3 primeros minutos de tu
                respuesta. No es necesario que leas y grabes la pregunta en voz
                alta.
              </p>
              <p>
                4. Por favor, contesta las preguntas detalladamente, con oraciones
                completas, con toda la gramática indicada en la pregunta y
                demostrando tu conocimiento del vocabulario acerca de los temas
                indicados. Por ejemplo, si la pregunta es "¿Cómo es tu nombre?",
                la respuesta adecuada es "Mi nombre es Javier Beltrán." No
                simplemente "Javier Beltrán".
              </p>
              <p>
                5. Para terminar presiona el botón azul, Una vez presionado, el
                sistema guardará automáticamente tu respuesta, esto podrá tomar
                algunos segundos.
              </p>
              <v-btn class="btn-step-three" rounded @click="activatesStepFive">
                Siguiente</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="container-main-student-five-step"  v-if="stepFive || this.getTest">
      <div class="container-two-step">
        <div class="responsive-logo-five-step"></div>
        <div class="bbeLogo-step"></div>
        <div class="container-main-five-step">
          <div class="container-image-step-five">
            <div class="image-step-five">
              <img class="logo-step-five" src="../assets/Recurso 4.png" alt="" />
            </div>
          </div>
          <div class="instructions-five-step">
            <h1>Paso 5</h1>
            <h2>Selecciona el idioma para empezar</h2>
            <p>
              Recuerda que tendrás solamente una oportunidad de responder cada
              pregunta, lee atentamente las preguntas antes de iniciar la
              grabación.
            </p>
            <v-btn
              v-for="language in getStudentLanguages"
              :key="language.id"
              @click="processResponse(language.id)"
              :disabled="isDisable(language.id)"
              class="btn-step-five"
              rounded
              >COMENZAR PRUEBA {{ language.name }}</v-btn
            >
          </div>
        </div>
      </div>
     </div>
  </div>
</template>

<script>
import RecorderTest from "@/components/RecorderTest";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    RecorderTest,
  },
  computed: {
    ...mapGetters([
      "getStudentLanguages",
      "getLanguageStatus",
      "getIntegrityPolicy",
      "getUser",
      "getTest",
      "getCurrentUser",
      "getSignedInUser",
      "getNameStudentApi",
    ]),
  },
  created() {
    this.loadStudentLanguages().then(async () => {
      await this.statusLanguages(this.getStudentLanguages);
    });
    this.getNameStudent().then(() =>{
      if (this.getNameStudentApi == false) {
        this.showForm = true;
      } else if(this.getNameStudentApi == true) {
        this.home = true;
      }
    });
    this.getNameStudentCompany();

    if (this.getTest) {
      this.stepFour = true;
    }
  },
  data() {
    return {
      administrativeSrc: require("../assets/student_image.jpg"),
      allowAccessSrc: require("../assets/allow_access.png"),
      examSrc: require("../assets/exam_instructions_image.jpg"),
      home: false,
      instructionsSrc: require("../assets/instructions_image.jpg"),
      logoSrc: require("../assets/logo_BBE_avatar.png"),
      questionaryPath: "/questionary",
      soundTesting: false,
      integrityPolicy: false,
      stepThree: false,
      stepFour: false,
      stepFive: false,
      stepOne: false,
      timeSrc: require("../assets/time_image.jpg"),
      dialogSoundTest: false,
      showForm: false,
      valid: true,
      name: "",
      nameRules: [
        v => v.length > 6 || "El nombre debe contener al menos 6 caracteres",
        v => v.trim().length > 6 || "No debe contener solo espacios en blanco",
        v => !!v || "El nombre es requerido"
      ]
    };
  },
  methods: {
    ...mapActions([
      "generateResponse",
      "loadStudentLanguages",
      "setStudentLanguage",
      "statusLanguages",
      "updateIntegrityPolicy",
      "updateStudentHome",
      "getNameStudent",
      "getNameStudentCompany"
    ]),
    showDialogSoundTest() {
      this.dialogSoundTest = !this.dialogSoundTest;
    },
    activateHome() {
      if(this.name != ""){
        this.showForm = false;
        this.home = true;
        this.updateStudentHome(this.name);
      }
    },
    activateStepOne() {
      this.home = false;
      this.stepOne = true;
    },
    activatesSoundTesting() {
      this.stepOne = false;
      this.soundTesting = true;
    },
    activatesStepThree() {
      this.soundTesting = false;
      if (localStorage.integrityPolicy == "false") {
        this.stepThree = true;
      } else {
        this.stepThree = false;
        this.stepFour = true;
      }
    },
    activatesStepFive() {
      this.stepFour = false;
      this.stepFive = true;
    },
    processResponse(languageId) {
      this.setStudentLanguage(languageId);
    },
    activatesIntegrityPolicy() {
      this.stepFour = true;
      this.soundTesting = false;
      this.stepThree = false;
      this.integrityPolicy = true;
      this.updateIntegrityPolicy(this.integrityPolicy);
    },
    isDisable(languageId) {
      let statusLanguages = this.getLanguageStatus;
      for (let i = 0; i <= statusLanguages.length; i++) {
        if (this.getLanguageStatus[i].languageId == languageId) {
          if (this.getLanguageStatus[i].status == false) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    validateLengthInput(value) {
      let split = value.split("");
      let letter = 0
      for (let i = 0; i < split.length; i++) {
        split[i] === " " ? console.log("vacio") : letter++;
      }
      return letter >= 6 ? true : "El campo debe contener al menos 6 caracteres";
    }
  }
};
</script>

<style media="screen" scoped>
.vjs-record .vjs-device-button.vjs-control {
  display: none;
}

.header{
  display: flex;
  justify-content: center;
}
.container-main-student {
  margin: 0;
  padding: 0 6%;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
  /* width: 100%; */
  /* margin-top: 10%; */
  margin:70px auto;
  max-width: 1200px;
}

.container-text {
  /* margin-right: 30px; */
}
.container-img {
  height: calc(65vh);
  width: 50%;
  background: url("../assets/Recurso 17-100.jpg");
  background-color: #fff;
  background-position: center;
  background-size: cover;
  /* z-index: 20; */
}

.container-logo-student {
  object-fit: cover;
  margin-bottom: 20px;
}
.container-description h1 {
  letter-spacing: 2px;
  margin-bottom: 5px;
  color: black;
  text-align: left;
}

.container-description p {
  font-size: 20px;
  color: black;
  text-align: left;
}

.btn-start {
  color: white !important;
  background-color: #005cf2 !important;
}
.container-student {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 80vh; */
  /* width: 80%; */
  /* margin-left: 150px; */
}
#square {
  background: linear-gradient(
    rgba(10, 95, 166, 0.99),
    rgba(105, 61, 181, 0.99)
  );
  height: 40%;
  width: 30vw;
  position: absolute;
  right: 0;
  bottom: 0;
}
#logo-page-student {
  height: 50px;
  width: 78px;
  background: url("../assets/Recurso 16-100.jpg");
  background-size: cover;
  position: relative;
  top: 40px;
  right: -90%;
  z-index: 25;
}

/* Step one styles */

.container-one-step {
  display: flex;
  /* height: 80vh;
  width: 100vw; */
  margin-top: 50px;
  justify-content: center;
  padding: 0 6%;
  align-items: center;
}

.container-main-student-one-step{
  max-width: 1200px;
  margin: auto;
}
.container-main-one-step {
  display: flex;
  /* width: 80vw; */
  /* margin-top: 3%; */
  /* width: 100%; */
  /* max-width: 1200px; */
  /* margin:70px auto; */
  justify-content: center;
  /* padding: 0 6%; */
}
.instructions-one-step {
  background: url("../assets/number-step-one.png");
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center;
  /* margin-top: 300px; */
  /* width: 40%; */
  /* margin-right: 10%; */
}
.instructions-one-step h1 {
  letter-spacing: 2px;
  font-size: 2rem;
}

.instructions-one-step p {
  font-size: 0.9em;
  color: black;
  text-align: left;
  padding-right: 10px;
}
.image-one-step {
  background: url("../assets/Prueba de sonido (2).png");
  background-size: contain;
  /* margin-top: 200px; */
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  width: 50%;
  margin-bottom: 10px;
}

.btn-step-one {
  color: white !important;
  background-color: #005cf2 !important;
  margin-top: 20px;
}

.bbeLogo-step-one {
  background: url("../assets/Bbe-stepOne.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 200px;
  width: 22vw;
  /* position: absolute; */
  left: 0;
}

.warning-step-one {
  background: linear-gradient(
    rgba(10, 95, 166, 0.99),
    rgba(105, 61, 181, 0.99)
  );
  width: 60vw;
  height: 95px;
  /* position: absolute; */
  right: 0;
}

.warning-step-one h1,
p {
  text-align: center;
}

.warning-step-one h1 {
  color: white;
  font-size: 25px;
  /* margin-top: 15px; */
}

.warning-step-one p {
  color: white;
  font-size: 17px;
}

/* Step 2 */

.container-main-student-two-step{
  max-width: 1200px;
  margin: auto;
}

.container-two-step {
  display: flex;
  /* height: 80vh; */
  /* width: 100vw; */
  justify-content: center;
}
.container-main-two-step {
  display: flex;
  /* width: 80vw; */
  /* margin-top: 3%; */
  justify-content: center;
  padding: 5% 6%;
}

.container-image-four-step {
  height: 100%;
  width: 40%;
  /* margin-right: 5%; */
}

.container-image-two-step {
  height: 100%;
  width: 40%;
  /* margin-right: 5%; */
}

.instructions-two-step {
  padding: 4%;
  height: 100%;
  width: 50%;
  overflow-y: scroll;
}
.instructions-two-step::-webkit-scrollbar {
  width: 3px;
}

.instructions-two-step::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #005cf2;
}

.logo-step-two {
  background: url("../assets/Recurso 16.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 20%;
  width: 80%;
  margin-top: 3%;
  margin-bottom: 3%;
}

.image-step-two {
  background: url("../assets/image-step-two.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  height: 80%;
  width: 100%;
}

.number-step-two {
  background: url("../assets/number-step-two.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  height: 120%;
  width: 100%;
  opacity: 0.6;
  position: relative;
  top: -10%;
  left: 4%;
}

.text-step-two {
  background: url("../assets/step-two-text.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  position: absolute;
  top: 65%;
  height: 10%;
  width: 50%;
  opacity: 0.9;
}

.instructions-two-step h1 {
  font-size: 1.8em;
  margin-bottom: 2%;
  letter-spacing: 1px;
}
.instructions-two-step h2 {
  font-size: 1.5em;
  margin-bottom: 2%;
  font-style: italic;
}

.instructions-two-step p {
  font-size: 1em;
  text-align: left;
  font-weight: 100;
  color: black;
}

.btn-step-two {
  color: white !important;
  background-color: #005cf2 !important;
  margin-top: 20px;
  width: 50%;
  height: 30%;
}

.bbeLogo-step {
  background: url("../assets/logoBbe-rounded.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 2%;
  height: 130px;
  width: 150px;
}
/* step three */

.container-main-student-three-step{
  max-width: 1200px;
  margin: auto;
}
.image-step-three {
  background: url("../assets/image-step-three.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  height: 78%;
  width: 100%;
}

.number-step-three {
  background: url("../assets/number-step-three.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  height: 120%;
  width: 100%;
  opacity: 0.6;
  position: relative;
  top: -10%;
  left: 4%;
}

.text-step-three {
  background: url("../assets/step-three-text.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  position: absolute;
  top: 75%;
  height: 10%;
  width: 50%;
  opacity: 0.9;
}
.instructions-three-step {
  color: black;
  height: 100%;
  width: 60%;
  overflow-y: scroll;
  padding: 0 5% 3%;
}
.instructions-three-step::-webkit-scrollbar {
  width: 3px;
}

.instructions-three-step::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #005cf2;
}

.instructions-three-step h1 {
  font-size: 2.6em;
  font-weight: bold;
  font-style: italic;
  text-align: left;
  font-weight: 100;
  color: #0f267c;
}

.instructions-three-step p {
  font-size: 1.1em;
  text-align: left;
  font-weight: 100;
  color: black;
  text-align: justify;
}

.btn-step-three {
  color: white !important;
  background-color: #005cf2 !important;
  width: 70%;
  height: 30%;
}
/* step four */

.container-main-student-four-step{
  max-width: 1200px;
  margin: auto;
}


.image-step-four {
  background: url("../assets/image-step-four.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  height: 78%;
  width: 100%;
}

.number-step-four {
  background: url("../assets/number-step-four.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  height: 120%;
  width: 100%;
  opacity: 0.6;
  position: relative;
  top: -10%;
  left: 4%;
}

.text-step-four {
  background: url("../assets/step-four-text.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  position: absolute;
  top: 75%;
  height: 10%;
  width: 50%;
  opacity: 0.9;
}
.instructions-four-step {
  color: black;
  /* height: 70vh; */
  width: 60%;
  padding: 2% 5%;
  overflow-y: scroll;
}
.instructions-four-step::-webkit-scrollbar {
  width: 3px;
}

.instructions-four-step::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #005cf2;
}

.instructions-four-step h1 {
  font-size: 50px;
  font-weight: bold;
  text-align: left;
  font-weight: 100;
  color: black;
  margin-top: -2%;
}
.instructions-four-step h2 {
  font-size: 25px;
  text-align: left;
  font-weight: 100;
  color: black;
  margin-top: 2%;
  margin-bottom: 2%;
}

.instructions-four-step p {
  font-size: 13px;
  text-align: left;
  font-weight: 100;
  color: black;
  text-align: justify;
  overflow-y: auto;
}

.btn-step-four {
  color: white !important;
  background-color: #005cf2 !important;
  margin-top: 6%;
  width: 70%;
  height: 30%;
}
.container-main-student-five-step{
  max-width: 1200px;
  margin: auto;
}

.container-main-five-step {
  display: flex;
  /* width: 100%; */
  padding: 0 6%;
  justify-content: space-between;
  align-items: center;
}

.container-image-step-five {
  height: 100%;
  width: 80%;
  /* padding: 0 3%; */
}

.image-step-five {
  background: linear-gradient(rgba(10, 95, 166, 0.83), rgba(105, 61, 181, 0.83)),
    url("../assets/Recurso 21-100.jpg");
  background-position: center;
  background-size: cover;
  height: 85vh;
  /* width: 80%; */
  margin: 20px 0;
}

.instructions-five-step {
  /* margin-top: 2%; */
  padding: 5%;
  /* width: 40%; */
  font-size: 13px;
  text-align: left;
  font-weight: 100;
  overflow-y: scroll;
  /* margin-bottom: 20px; */
}
.instructions-five-step::-webkit-scrollbar {
  width: 3px;
}

.instructions-five-step::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #005cf2;
}

.instructions-five-step h1 {
  font-size: 2.6em;
  font-weight: bold;
  text-align: left;
  font-weight: 100;
  color: black;
  margin-top: 6%;
}

.instructions-five-step h2 {
  font-size: 2.0em;
  font-weight: bold;
  text-align: left;
  font-weight: 100;
  color: black;
  margin-top: 4%;
  margin-bottom: 4%;
}
.instructions-five-step p {
  font-size: 1.3em;
  font-weight: bold;
  text-align: left;
  font-weight: 100;
  color: black;
}

.btn-step-five {
  color: white !important;
  background-color: #005cf2 !important;
  margin-top: 2%;
  width: 70%;
  height: 30%;
}

.logo-step-five {
  background: linear-gradient(rgba(35, 40, 79, 0.8));
  justify-content: center;
  margin-bottom: 2%;
  align-items: center;
  height: 100%;
  width: 100%;
  object-fit: contain;
}


@media (min-width: 320px) and (max-width: 765px) {
.container-main-student {
  /* height: 110vh; */
  margin-bottom: 20px;
  margin-top: 20px;
}

.container-text{
  width: 100%;
  margin:0;
}

#logo-page-student, .bbeLogo-step{
  display: none;
}

.container-student{ 
  padding: 0 8%;
  /* height: 80vh; */
  width: 100%;
}

.container-img{
  display: none;
}

#square{
  height:30%;
  width: 60%;
  right: calc(-25%);
}

.container-description h1 {
  letter-spacing: 1px;
  font-size: 2.5rem;
}

.container-description p {
  font-size: 1.5rem;
}


/* Step one */
  .container-main-student-one-step{
    /* height: 112vh; */
  }
  .warning-step-one {
    /* width: 100vw; */
    /* height: 25vh; */
    /* left: calc(15%); */
    width: auto;
    position: inherit;
  }
  .warning-step-one h1 {
    font-size: 1.2em;
  }
  .warning-step-one p {
    font-size: 0.9em;
  }

  .container-one-step{
    padding: 0 8%;
    /* height: 85vh; */
    width: auto;
    flex-direction: column-reverse;
    /* height: 100vh; */
    /* margin-left: 20px; */
    /* width: 105vw; */
  }

  .container-main-one-step{
    /* height: 75vh;
    margin-top: 13vh;
    width: 160vw; */
    flex-direction: column-reverse;
  }
  .instructions-one-step{
    background-size: contain;
    margin:0;
    padding: 0 0 20px;
    width: 100%;
    /* margin-right: 0; */

  }
  .image-one-step{
    height: 268px;
    width: 100%;
    margin-top: 0px;
  }

  /* Two step */
   .container-main-student-two-step{
    /* height: 112vh; */
  }
  .container-two-step{
    flex-direction: column;
    /* margin-top: 80px;
    width: 90vw; */
    width: 100%;
    height: auto;
  }
  .container-main-two-step{
    width: 100%;
  }
  .container-main-student-four-step .container-main-two-step{
    padding: 5% 2%;
  }
  .responsive-logo-two-step{
    background: url("../assets/Recurso 16.png");
    background-position:center;
    background-repeat: no-repeat;
    height:150px;
    width: 100%;
  }

  .container-image-two-step , .container-image-four-step, .container-image-step-five{
    display: none;
  }
  
  .instructions-two-step {
    width: 100%;
    /* margin-left: calc(20%); */
  }

  .btn-step-two {
    font-size: 0.9em !important;
    width: 90% !important;
    margin:20px;
  }

  /* steph three */
  .container-main-student-three-step ,.container-main-student-four-step ,.container-main-student-five-step{
    /* height: 114vh; */
  }
  .instructions-three-step{
    /* margin-top: 35vh; */
    /* height: 90vh; */
    /* width: 90%; */
    /* margin-left: calc(20%); */
    padding: 0 8% 20px;
    width: 100%;
  }
  .instructions-three-step h1{
    font-size: 1.8em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  .instructions-three-step p{
    font-size: 1.3em;
    margin-right: 10px;
  }
  .btn-step-three{
    margin-top: 20px;
    height: 40px !important;
    width: 100%;
  }

  .responsive-logo-three-step ,.responsive-logo-four-step , .responsive-logo-five-step{
    /* position: absolute; */
    right:0;
    top: 2%;
    background: url("../assets/Recurso 16.png");
    background-position:center;
    background-repeat: no-repeat;
    height:150px;
    width: 100%;
  }

  .instructions-four-step{
    /* height: 90vh; */
    width:100%;
    /* margin-top: 180px; */
    padding: 5px 3%;
  }
  .instructions-four-step h1{
    font-size: 1.7em;
    margin-top: 2px;
  }
  .instructions-four-step h2{
    font-size: 18px;
  }
  .instructions-four-step p{
    font-size: 0.8em;
    /* margin-left: 20px; */
  }
  .instructions-four-step button{
    margin-bottom: 5px;
  }
  /* step five */
  .instructions-five-step{
    width:100%;
    /* margin-top: 25vh; */
    /* margin-left: calc(10%); */
  }
  .instructions-five-step button{
    width: 100%;
  }
}

@media (max-width: 340px){
  #logo-page-student, #square{
  display: none;
}

.container-student{
  /* margin-top: 10%;
  margin-left: calc(25%) */
}
}

@media (min-width: 320px) and (max-width: 553px) {
  
  .bbeLogo-step-one {
    display: none;
  }

  .container-student{
    padding: 0 8%;
  }

  .instructions-two-step h1 {
    font-size: 1.1em;
  }
  .instructions-two-step h2 {
    font-size: 1em;
  }
  .instructions-two-step p {
    font-size: 0.9em;
  }
  .container-image-two-step {
    display: none;
  }

}

@media (min-width: 553px) and (max-width: 768px) {
  .bbeLogo-step-one {
    display: none;
  }

  .number-step-two {
    height: 80%;
  }
  .btn-step-two {
    width: 50% !important;
  }
  .instructions-four-step h1 {
    font-size: 1.6em;
  }
  .instructions-four-step h2 {
    font-size: 1.2em;
  }
  .instructions-four-step p {
    font-size: 0.9em;
  }
  .container-image-four-step {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .bbeLogo-step{
    display: none;
  }
  .bbeLogo-step-one{
    height: 30vh;
  }

  .container-main-two-step{
    width: 100%;
    padding: 0 10%;
  }

  .text-step-two{
    height: 70px;
    top:70%;
  }
  .instructions-two-step{
    margin-left: 10%;
  }
  .instructions-two-step h1 {
    font-size: 1.6em !important;
  }
  .instructions-two-step h2 {
    font-size: 1.4em;
  }
  .instructions-two-step p {
    font-size: 1.1em;
  }
  .btn-step-two {
    font-size: 0.75em !important;
    width: 40% !important;
  }

  .instructions-four-step h1 {
    font-size: 1.3em;
  }
  .instructions-four-step h2 {
    font-size: 1.2em;
  }
  .instructions-four-step p {
    font-size: 0.8em;
  }
  .instructions-five-step h1 {
    font-size: 2.5em;
  }
  .instructions-five-step h2 {
    font-size: 2.4em;
  }
  .instructions-five-step p {
    font-size: 1.2em;
  }
    .instructions-five-step button{
    width: 100%;
  }
}
</style>
