<template class="">
  <v-app id="inspire">
      <v-container class="container-box" fluid  v-show="!guest">
       
        <v-row class="container-main">
           <div class="container-results">
            <Errors :errors="getErrors" />
            <Results :results="getResults" />
          </div>
          <v-col class="container-items" md="8" sm="8"  >
            <v-col class="container-logo">
                <img class="logo" src="../assets/Recurso 4.png" alt="">
              <div class="footer-logo">by BBE Languages</div>
            </v-col>
            <v-col class="container-form" >
              <div class="menu">
                <button id="sign-btn" class="btn-menu-sign mx-2">INGRESAR</button>
                <button class="btn-menu-sign mx-2" @click="setGuest()">REGISTRARSE</button>
              </div>
              <div class="form align-content-center"> 
                 <label class="label-form" for="email">EMAIL</label>
                 <input class="form-input" id="email" type="text" placeholder="****@bbelenguages.com" v-model="getCurrentUser.email">
                 <label class="label-form" for="password">CONTRASEÑA</label>
                 <input class="form-input" id="password" type="password" placeholder="********"  v-model="getCurrentUser.password"
                  @keyup.enter="sendSignIn()"
                  @click:append="show = !show">
                <v-btn  rounded class="btn-sign"  @click="sendSignIn()">SIGN IN</v-btn>
              </div>
              <div class="footer">
                <a href="https://www.linkedin.com/company/bbe-languages" target="_blank">
                  <img class="logos-footer" src="../assets/Recurso 15.png" alt="logoLinkdin">
                </a>
                <a href="https://www.facebook.com/bbelanguages" target="_blank">
                  <img class="logos-footer" src="../assets/Logo Facebook_1.png" alt="logo Facebook">
                </a>
                <a href="">
                  <img class="logos-footer" src="../assets/Logo Whatsapp_1.png" alt="logo Whatsapp">
                </a>
                <a href="https://www.instagram.com/bbelanguages/" target="_blank">
                  <img class="logos-footer" src="../assets/Logo Instagram_1.png" alt="Logo Instagram">
                </a>
                <a href="https://twitter.com/bbelanguages" target="_blank">
                  <img class="logos-footer" src="../assets/Logo Twitter_1.png" alt="Logo Twitter">
                </a>
                <p>© Tabone Enterprises Colombia - Todos los derechos reservados</p>
              
              </div>
            </v-col>
          </v-col>
        </v-row>
        </v-container>
        <!-- Guest register -->
        <v-container id="container-box-register" class="container-box" fluid v-show="guest">
        <v-row class="container-main-register" >
           <div class="container-results">
            <Errors :errors="getErrors" />
            <Results :results="getResults" />
          </div>
          <v-col class="container-items" sm=8 md=10 >
            <v-col class="container-logo" cols="4">
                <img class="logo" src="../assets/Recurso 4.png" alt="">
              <div class="footer-logo">by BBE Languages</div>
            </v-col>
            <v-col class="container-form pa-10" style="text-align:center"  xs="10">
              <div id="menu" class="menu-register">
                <button class="btn-menu-sign mx-2" @click="setSignIn()">INGRESAR</button>
                <button id="register-btn" class="btn-menu-sign mx-2" >REGISTRARSE</button>
              </div>
              <div class="form-register"> 
                <div class="container-name">
                 <label class="label-form-register" for="name">Name</label>
                 <input class="form-input-register" id="name" type="text" placeholder="Pedro Perez" v-model="getGuestUser.name">
                </div>
                <div  class="container-cedula">
                 <label class="label-form-register" for="cedula">Cedula</label>
                 <input class="form-input-register" id="cedula" type="text" placeholder="303030" v-model="getGuestUser.cedula">
                </div>
                <div class="container-code">
                 <label class="label-form-register" for="access-code">Access Code</label>
                 <input class="form-input-register acces-code" id="access-code" type="text" placeholder="332" v-model="getGuestUser.access_code">
                </div>
                <div  class="container-email">
                 <label class="label-form-register" for="email">Email</label>
                 <input class="form-input" id="email-register" type="text" placeholder="****@bbelenguages.com" v-model="getGuestUser.email">
                </div>
                <div  class="container-languages">
                  <v-select
                            v-model="getGuestUser.language_ids"
                            :items="getDropdownLanguages"
                            item-text="name"
                            item-value="id"
                            label="Select Languages"
                            :menu-props="{ top: true, offsetY: true }"
                            multiple
                            chips 
                            hint="Pick the languages the student is going to do test"
                            persistent-hint
                          
                            class="select-form-2"
                          ></v-select>
                </div>
                <div class="container-btn">
                  <v-btn  rounded class="btn-register"  @click="registerGuest()">Register as a guest</v-btn>
                </div>
              </div>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
  </v-app> 
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import Errors from "@/components/Errors";
import Results from "@/components/Results";

export default {
  created() {
    this.initializeStudent();
    this.loadLanguagesDropdownGuest();
    this.setSignIn();
  },
  data() {
    return {
      imageSrc: require("../assets/00_bogota.jpg"),
      logoSrc: require("../assets/logo_BBE_avatar.png"),
      show: false,
      guest:false,
      
      icons: [
        {
          icon: "mdi-facebook",
          url: "https://www.facebook.com/bbelanguages",
        },
        {
          icon: "mdi-twitter",
          url: "https://twitter.com/bbelanguages",
        },
        {
          icon: "mdi-linkedin",
          url: "https://www.linkedin.com/company/bbe-languages",
        },
        {
          icon: "mdi-instagram",
          url: "https://www.instagram.com/bbelanguages/",
        },
      ],
        items: ['Ingles', 'Español', 'Portugues', 'Aleman','Frances', 'Examen oral de Talento Bilingue'],
        companies:['Company 1', 'Company 2', 'Company 3']
    };
  },
  components: {
    Errors,
    Results
  },
  computed: {
    ...mapGetters(["getCurrentUser", "getErrors", "getSignedIn","getEditedStudent","getGuestUser","getDropdownLanguages","getResults"]),
  },
  methods: {
    ...mapActions(["signIn","createStudent","initializeStudent","loadLanguagesDropdownGuest","signUp"]),
    sendSignIn() {
      this.signIn();
    },
    registerGuest(){
      this.signUp(this.getGuestUser)
        .then(
          this.getCurrentUser.email = this.getGuestUser.email,
          this.getCurrentUser.password = this.getGuestUser.cedula,
          this.resetForm(),
          setTimeout(()=>{
           this.setGuest()
          },3000)
        )
        .catch( error =>{
           console.error(error);
          }
        )
    },
    setGuest(){
      this.guest = true
      var element = document.getElementById("register-btn");
        element.classList.add("active");
    },
    setSignIn(){
      this.guest = false
      var element = document.getElementById("sign-btn");
        element.classList.add("active");
    },
    resetForm(){
     this.getGuestUser.name = ""
     this.getGuestUser.access_code = ""
     this.getGuestUser.cedula = ""
     this.getGuestUser.company_id= ""
     this.getGuestUser.email= ""
     this.getGuestUser.language_ids = []
     this.getGuestUser.response_email_to = ""
    },
  }
}

</script>

<style media="screen" scoped>

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');

.v-parallax__image {
  /* transform: none !important; */
  display: flex;
  height: 100% !important;
  width: 100% !important;
}
.col-input{
  padding: 2 0 !important;
  height: 4em;
}
.button-container{
  margin:0;
  padding:0 !important;
}
/* .acces-code{
  height: 3em !important;
  width: 90% !important;
  margin-bottom: 0 !important;
} */
.container-box{
  padding: 0;
  margin: 0;
}
.container-results{
  display: flex;
  justify-content: center;
  width: 80%;
  margin-left: 10%;
}
.container-main{
  background:url("../assets/Background-container-100.jpg");
  padding: 0;
  margin: 0;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  background-size: cover;
  background-position: center;
  min-height: 550px;
}
.container-main-register{
  background:url("../assets/Background-container-100.jpg");
  padding: 0;
  margin: 0;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  background-size: cover;
  background-position: center;
  min-height: 550px;
  /* display: flex;
  flex-direction: column; */
}
.container-items{
  display: flex;
  padding: 0% !important;
  margin: 0;
  height: 80vh;
  width: 100%;
  max-width: 1200px;
  min-height: 550px;
  /* margin: auto; */
}
.container-logo{
  background: linear-gradient( rgba(10,95,166,.93), rgba(105,61,181,.93)),url("../assets/Recurso 21-100.jpg");
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  background-size: cover;
  background-position: center;
}
.container-form{
  background: url("../assets/Recurso 3-100.jpg");
  background-size: cover;
  background-position: center;
 
}

.container-main .container-form{
 display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-evenly;
  /* overflow-y:scroll; */
}


.container-main-register .container-form{
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.menu{
   /* margin-top: 15%; */
  /* height: 15%; */
  text-align: center; 
  /* display: flex; */
}

.menu-register{
  /* margin-top:4%; */
  /* margin-bottom:2%; */
  height: 10%;
}
.btn-menu-sign{
  display: inline-block;
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1pt;
}
.active {
  border-bottom: blue solid 2px;
}

.btn-menu-sign:active{
  border-bottom: blue solid 2px;
}
.container-main .container-form .form{
  margin-top: 2%;
  height: auto;
  padding: 2% 10%;
}
.form-register{
  display: grid;
  grid-template-areas:
  "name name"
  "cedula code"
  "email email"
  "languages languages"
  "btn btn";
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}

.container-name{
  width: 90%;
  grid-area: name;
}
.container-name input {
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  /* margin-bottom: 0; */
  margin:0 0 20px;
}
.container-cedula{
  width: 90%;
  grid-area: cedula;
}
.container-company{
  grid-area: company;
}
.container-code{
  width:90%;
  grid-area: code;
}
.container-email{
  width: 90%;
  /* height: 100%; */
  grid-area: email;
}
.container-email input {
  margin:0 0 20px;
}
.container-languages{
  width: 90%;
  grid-area: languages;
  margin: 20px 0;
}

.container-languages .select-form-2{
  margin:20px 0;
}
.container-btn{
  width: 90%;
  grid-area: btn;
}


.form-register div{
  align-self: center;
  justify-self: center;
}
.container-main .container-form .footer{
  margin-top:1%;
  height: auto;
  text-align: center ;
}

.logos-footer{
  height: 1.5em;
  width: 1.5em;
  margin-left: 10%;
  margin-bottom: 5%;
}

.footer p{
  color: white;
  font-size: 13px;
}

.logo{
  background: linear-gradient(rgba(35,40,79,.8));
  justify-content: center;
  margin-bottom: 2%;
  align-items: center;
  height: 70%;
  width: 100%;
  object-fit: contain;
}
.footer-logo{
  font-family: 'Lato', sans-serif;
  color: white;
  text-align: center;
}

.form-input{
  background-color: rgba(107, 114, 140,0.75);
  border-radius: 50px;
  border:solid 1px rgba(107, 114, 140,0.75); 
  color: white !important;
  /* height: 8%; */
  width: 100%;
  padding:3% 10%;
  margin-bottom: 6%;
}
.form-input-register{
  background-color: rgba(107, 114, 140,0.75);
  border-radius: 50px;
  border:solid 1px rgba(107, 114, 140,0.75); 
  color: white !important;
  width: 85%;
  height: 40px;
  padding: 5px 10px;
  margin-bottom: 6%;
}
#email-register{
  margin-bottom: 0%;
}
#languages-register{
  margin-bottom:10px;
}
.form-input:focus{
outline: none;
}
.form-input:active{
outline: none;
}
.label-form{
  color: white;
  font-size: 12px;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  padding-left: 5%;
  letter-spacing: 2pt;
  text-align:left !important;
}
.label-form-register{
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: white;
  font-size: 14px;
  letter-spacing: 2pt;
}

.select-form{
  background-color: rgba(107, 114, 140,0.75);
  color: white;
  border-radius: 50px;
  border:solid 1px rgba(107, 114, 140,0.75); 
  width: 80%;
  padding:5% 10%;
  outline: none;
  margin-bottom: 7%;
}
.select-form-2{
  background-color: rgba(107, 114, 140,0.75);
  color: white;
  border-radius: 35px;
  border:solid 1px rgba(107, 114, 140,0.75); 
  width: 100%;
  padding:1% 4%;
  outline: none;
}

.btn-sign{
 width: 100%;
 background-color: #005CF2 !important;
 color: white !important;
 font-weight: bold !important;
 /* margin-top: 10%; */
 display: inline-block;
}
.btn-register{
 width: 100%;
 background-color: #005CF2 !important;
 color: white !important;
 font-weight: bold !important;
}


@media (min-width: 320px) and (max-width: 765px) {

  .container{
    padding: 0;
    margin:0;
  }
  .container-box{ 
    /* display: none; */
  }
  .container-main{
    margin: 0;
  }
  .container-items{
    height: 100%;
    /* margin-top: 40px; */
    width: 100%;
  }

  .menu{
    display:flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15%;
  }

  .form{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    height: 60%;
    width: 100%;
  }

  .form .form-input{
    /* width: 50vw; */
    height: 6vh;
    width: 100%;
    margin-bottom: 20px;
  }

  .form .label-form{
    font-size: 1.2em;
  }

  .btn-sign {
    margin-top: 20px;
  }

  .btn-menu-sign{
    align-self: center;
     font-size: 1.3em;
  }
  .container-logo{
    display: none;      
  }
  .container-form {
    width: 100% !important;
  }


  .container-main-register .container-items{
    width: 100%;
    margin: 0;
  } 

  .container-main-register .container-form{
    overflow-y: scroll;
  }


  .form-register{
    /* margin-top: 5vh; */
    padding: 20px 5% 0;

    grid-template-areas:
    "name name"
    "cedula cedula"
    "code code"
    "email email"
    "languages languages"
    "btn btn";
  }

  .form-register .container-name, .container-cedula, .container-code , .container-email , .container-languages{
    width: 100%;
  }

  .btn-register{
    margin-top: 0;
    font-size: 0.74rem !important;
  }
  .container-btn{
    width: 100%;
    margin-top:10px;
  }
  .label-form-register{
    font-size: 0.76rem !important;
  }
  .form-input-register{
    width: 100%;
  }
  #email-register{
    height: 55%;
  }
}
</style>

