<template>
  <v-card-text>
    <v-container>

      <v-row>
        <v-col cols="12" v-if="getIsUserAdmin">
          <v-select
          v-model="filterParams.level"
          :items="getDropdownQuestionLevels"
          item-text="name"
          item-value="id"
          :menu-props="{ maxHeight: '400' }"
          label="Select Level"
          clearable
          chips
          solo
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" v-if="getIsUserAdmin">
          <v-select
          v-model="filterParams.language_id"
          :items="getDropdownLanguages"
          item-text="name"
          item-value="id"
          label="Select Languages"
          :menu-props="{ top: true, offsetY: true }"
          clearable
          chips
          solo
          ></v-select>
        </v-col>
      </v-row>






      <v-row>
        <v-col cols="12" align="right">
          <v-btn
          elevation="2"
          large
          x-large
          dark
          color="bbe-blue"
          @click="filter"
          >Filter</v-btn>
        </v-col>
      </v-row>




    </v-container>
  </v-card-text>
</template>





<script>

import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "getDropdownQuestionLevels",
      "getDropdownLanguages",
      "getIsUserAdmin",
    ])
  },
  created() {
    // this.loadTeachers();
  },
  data() {
    return {
      filterParams: {
        language_id: "",
        level: "",
      }
    }
  },
  methods: {
    ...mapActions([
      "filterQuestions",
      "setShowFilterActive"
    ]),
    filter() {
      this.filterQuestions(this.filterParams)
      this.setShowFilterActive()
    }
  },
  name: 'StudentFiler'
}
</script>
