<template id="">
  <div>
    <User :users="getUsers" />
  </div>
</template>
<script>
import User from "@/components/users/User";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Users",
  data() {
    return { };
  },
  components: {
    User
  },
  computed: {
    ...mapGetters(["getUsers"])
  },
  methods: {
    ...mapActions(["loadUsers"])
  },
  created() {
    this.loadUsers();
  }
}
</script>
