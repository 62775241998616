<template id="">
  <div>
    <Question :questions="getQuestions" />
  </div>
</template>
<script>

import Question from "@/components/questions/Question";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Questions",
  data() {
    return { };
  },
  components: {
    Question
  },
  computed: {
    ...mapGetters(["getQuestions"])
  },
  methods: {
    ...mapActions(["loadQuestions"])
  },
  created() {
    this.loadQuestions();
  }
}
</script>
