<template>
  <div>
    <Loading v-if="isInfoLoading"/>
    <v-card v-else class="mx-3 my-5 pa-1">
      <Results :results="getResults" />
      <v-toolbar color="bbe-blue" dark >

        <v-toolbar-title>TEACHERS</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-menu offset-y offset-yorigin="center center">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item  @click="editItem">
              <v-list-item-title>NEW TEACHER</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>

      <v-card class="mx-1 my-1 pa-3">
        <v-data-table
        :headers="headers"
        :items="teachers"
        sort-by="name"
        :search="search"
        class="pa-1 ma-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="getTeacherDialogEdit" persistent>
                <Errors :errors="getErrors" />
                <v-card>
                  <v-card-title class="headline justify-center bbe-blue white--text" >
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-main class="pt-1">
                    <v-card-text>
                      <v-row>
                        <v-col sm="6">
                          <v-text-field v-model="editedItem.name" label="Name">
                          </v-text-field>
                        </v-col>
                        <v-col sm="6">
                          <v-text-field v-model="editedItem.email" label="Email">
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-main>

                  <v-card-actions class="d-flex justify-center">
                    <v-btn class="bbe-blue" dark text @click="save">
                      Save
                    </v-btn>
                    <v-btn class="bbe-orange" dark text @click="close">
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- The following dialog is for delete item -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline justify-center bbe-blue white--text" >
                    Student - Delete
                  </v-card-title>
                  <div class="text--primary text-center ma-2">
                    Are you sure you want to delete this item?
                  </div>
                  <v-card-actions class="justify-center">

                    <v-btn class="bbe-orange" dark text @click="deleteItemConfirm">
                      DELETE
                    </v-btn>
                    <v-btn class="bbe-blue" dark text @click="closeDelete">
                      CANCEL
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogShow" max-width="800px">
                <v-card>
                  <v-card-title class="headline justify-center bbe-blue white--text" >
                    Teacher - Show
                  </v-card-title>

                  <v-card-text>
                    <v-main class="pa-1">
                      <v-row>
                        <v-col sm="2" class="font-weight-bold">
                          Name
                        </v-col>

                        <v-col sm="4">
                          {{ editedItem.name }}
                        </v-col>


                        <v-col sm="2" class="font-weight-bold">
                          Email
                        </v-col>

                        <v-col sm="4">
                          {{ editedItem.email }}
                        </v-col>
                      </v-row>
                    </v-main>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- The following are the icons for the CRUD actions -->
          <template v-slot:item.actions="{ item }" class="justify-around">
            <v-icon medium class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="showItem(item)">
              mdi-eye
            </v-icon>
            <v-icon medium @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import Errors from "@/components/Errors";
import Results from "@/components/Results";
import Loading from "@/components/Loading";

export default {
  name: "Teacher",
  components: {
    Errors,
    Loading,
    Results
  },
  data() {
    return {
      dialogEdit: false,
      dialogDelete: false,
      dialogShow: false,
      editedIndex: -1,
      editedItem: {
        name: "",
        email: ""
      },
      defaultItem: {
        name: "",
        email: ""
      },
      search: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  props: {
    teachers: {
      type: Array,
      required: true
    },
    // errors: { }
  },
  computed: {
    ...mapGetters([
      'getErrors',
      "getResults",
      "isInfoLoading",
      "getTeacherDialogEdit"
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Teacher" : "Edit Teacher";
    }
  },
  created(){
    this.initializeTeacher();
  },
  methods: {
    ...mapActions([
      "createTeacher",
      "deleteTeacher",
      "initializeTeacher",
      "updateTeacher",
      "setTeacherDialogEditStatus"
    ]),

    save() {
      // if index is different to -1 means the record was modified
      if (this.editedIndex > -1) {
        this.updateTeacher(this.editedItem);
      } else {
        // this means is a new record
        this.createTeacher(this.editedItem);
      }
    },
    close() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.setTeacherDialogEditStatus()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editItem(item) {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.editedIndex = this.teachers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.setTeacherDialogEditStatus()
    },
    deleteItem(item) {
      this.editedIndex = this.teachers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deleteTeacher(this.editedItem);
      this.closeDelete();
    },
    showItem(item) {
      this.showedIndex = this.teachers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogShow = true;
    }
  }
};

</script>

<style scoped lang="scss"></style>
