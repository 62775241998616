<template>
  <div class="">
    <Response :responses="getResponses" />
  </div>
</template>

<script>
import Response from '@/components/responses/Response';
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Response
  },
  computed: {
    ...mapGetters(["getResponses"])
  },
  created() {
    this.loadResponses(this.filterParams);
  },
  data() {
    return {
      filterParams: {
        page: 1,
        per_page: 10
      }
    };

  },
  methods: {
    ...mapActions(["loadResponses"])
  },
  name: "Responses",
}
</script>

<style media="screen">

</style>
