import Vue from "vue";
import VueRouter from "vue-router";
import Companies from "../views/Companies";
import Home from "../views/Home";
import Languages from "../views/Languages"
import Questionary from "../components/Questionary"
import Questions from "../views/Questions";
import Responses from "../views/Responses";
import UnassingedTeacher from "@/components/responses/UnassingedTeacher"
import NotGradedResponses from "@/components/responses/NotGradedResponses"
import UnsentResponses from "@/components/responses/UnsentResponses"
import EnableTest from "@/components/students/EnableTest"
import Settings from "../views/Settings";
import Students from "../views/Students";
import Teachers from "../views/Teachers";
import NextTest from "../components/NextTest"
import Users from "../views/Users";

Vue.use(VueRouter);

const routes = [
  {
    path: "/responses",
    component: Responses
  },
  {
    path: "/unassignedRespones",
    component: UnassingedTeacher
  },
  {
    path: "/unsentResponses",
    component: UnsentResponses
  },
  {
    path: "/notGradedResponses",
    component: NotGradedResponses
  },
  {
    path: "/companies",
    component: Companies
  },
  {
    path: "/languages",
    component: Languages
  },
  {
    path: "/questions",
    component: Questions
  },

  {
    path: '/questionary',
    component: Questionary
  },

  {
    path: "/settings",
    component: Settings
  },
  {
    path: "/students",
    component: Students
  },
  {
    path: "/users",
    component: Users
  },
  {
    path: "/teachers",
    component: Teachers
  },
  {
    path: "/nextTest",
    component: NextTest
  },
  {
    path:"/EnableTest",
    component:EnableTest
  },
  {
    path: "/",
    component: Home
  },
  {
    path: "*",
    redirect: "/"
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
