<template>
  <div class="spinner">
    <v-dialog v-model="isInfoLoading" persistent>
      <v-card class="mx-auto my-12 fill-height align-self-center" max-width="900" height='500' >
        <v-row no-gutters class="">
          <v-col cols='12' class="align-self-center my-10">
            <div class="fill-height align-center " align="center">
              <p class="font-weight-black headline">
                LOADING
              </p>
            </div>
          </v-col>
        </v-row>

        <v-row no-gutters class="">
          <v-col cols='12' class="align-self-center my-10">
            <div class="fill-height align-center" align="center">
              <v-progress-circular
              :size="220"
              color="bbe-blue"
              class="justify-center align-self-center"
              indeterminate
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>



<script>

import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "isInfoLoading",
    ])
  }
}



</script>
