<template>
  <div class="">
       <Results :results="getResults" />
       <Errors :errors="getErrors" />
    <v-alert type="error" v-if="getNoAudios"
      >There are no audios to show</v-alert
    >
    <v-treeview
      v-else
      :items="loadItems"
      :load-children="fetchChildren"
      :activatable="activatable"
      :hoverable="hoverable"
      :dense="dense"
      :open-on-click="openOnClick"
      color="bbe-blue"
      :rounded="rounded"
      return-object
      @update:active="playItem"
      class=" pa-2"
      ><div class="pa-1"></div>
    </v-treeview>
    <v-btn v-if="getShowOptions && getIsUserAdmin" @click="openDelete" color="#CE2727" class="ml-2 mr-2" dark
      >DELETE Selected audio</v-btn
    >
    <v-btn v-if="getShowOptions && getIsUserAdmin" @click="openDownload" class="bbe-blue " dark
      >DOWNLOAD Selected audio</v-btn
    >

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center bbe-blue white--text" >
           Audio - Delete
        </v-card-title>
        <div class="text--primary text-center ma-2">
           Are you sure you want to delete this item?
        </div>
        <v-card-actions class="justify-center">

        <v-btn class="bbe-orange" dark text @click="deleteAudioBtn()">
          DELETE
        </v-btn>
        <v-btn class="bbe-blue" dark text @click="closeDelete">
          CANCEL
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>

</template>


<script>
import { mapGetters, mapActions } from "vuex";
import Results from "@/components/Results";
import Errors from "@/components/Errors";
export default {
  name: "TreeView",
  data() {
    return {
      dense: true,
      activatable: true,
      hoverable: true,
      openOnClick: true,
      rounded: true,
      selectedColor: "accent",
      dialogDelete:false
    };
  },
  components:{
    Results,
    Errors
  },
  computed: {
    ...mapGetters([
      "loadItems",
      "getShowWaveSurfer",
      "getNoAudios",
      "getEditedResponse",
      "getShowOptions",
      "getAudioSelected",
      "getResults",
      "getErrors",
      "getAudioBlob",
      "getIsUserAdmin"
    ]),
  },
  methods: {
    ...mapActions(["loadTree", "fetchChildren", "playItem", "deleteAudio","changueOptions"]),

    deleteAudioBtn() {
      this.dialogDelete = false;
      let path = this.getAudioSelected[0].answerId
      this.deleteAudio(path)
    },

     closeDelete() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.dialogDelete = false;
      this.changueOptions();
    },
    openDelete(){
      this.dialogDelete = true
    },
    openDownload(){
      let link = document.createElement('a');
	    link.href = window.URL.createObjectURL(this.getAudioBlob);
      let filename = (this.getAudioSelected[0].name.split("."));
	    link.download = filename[0];
	    document.body.appendChild(link);
	    link.click()
	    document.body.removeChild(link);
    }
  },
};
</script>

<style>
/* .v-treeview {
  overflow-x:inherit;
} */
#algo {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.v-window.v-item-group {
  flex-grow: 1;
}

.v-window__container {
  height: 100%;
}
</style>
