<template>
  <div class="ma-1 pa-5">

    <v-card max-height="450" max-width="550">
      <video id="recorderView" playsinline class="video-js vjs-default-skin" style="width: 100%;">
        <p class="vjs-no-js">
          To view this video please enable JavaScript, or consider upgrading to a
          web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video.
          </a>
        </p>
      </video>

      <v-card-text class="d-flex flex-column justify-center align-center">

        <v-btn
          class="mx-2"
          fab
          dark
          large
          color="red"
          v-if="getIsRecording"
          @click="startsRecording()"
        >
          <v-icon dark>
            mdi-checkbox-blank-circle
          </v-icon>
        </v-btn>


        <v-btn
          class="mx-2"
          fab
          dark
          large
          color="blue"
          v-if="getStopRecording"
          @click="stopsRecord()"
        >
          <v-icon dark>
            mdi-stop
          </v-icon>
        </v-btn>

      </v-card-text>

    </v-card>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      // continueQuestion: true,
      startRecording: false
    }
  },
  methods: {
    ...mapActions([
      "startsRecorder",
      "startsRecording",
      "playRecord",
    ]),
    stopsRecord() {
      this.getPlayer.record().stopDevice()
    },
    confirmRecording(){
      this.startRecording = true
    },
  },
  components:{

  },

  computed: {
    ...mapGetters([
      'getIsRecording',
      'getPlayer',
      'getStopRecording'
    ])
  },

  mounted() {
    this.startsRecorder('recorderView');
    this.getPlayer.on('deviceReady', () => {
      this.getPlayer.record().start();
    });
    this.getPlayer.on('deviceError', () => {
      console.log('device error:', this.getPlayer.deviceErrorCode);
    });
    this.getPlayer.on('error', (element, error) => {
      console.error(error);
    });

  },
  beforeDestroy() {
    if (this.getPlayer.player) {
      this.getPlayer.dispose();
    }
  }


}

</script>

<style media="screen">
.vjs-record .vjs-device-button.vjs-control {
  display: none;
}
</style>
