import WaveSurfer from "wavesurfer.js";
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js";
import RegionPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min.js";
import axios from "axios";

const state = {
  audioState: true,
  before: undefined,
  currentVolume: null,
  disabled: undefined,
  durationTime: "Please select an audio",
  loading: "invisible",
  muteunmute: false,
  prevAnnotation: undefined,
  prevRow: undefined,
  questionSelectedLevel: "",
  questionSelectedTitle: "",
  region: undefined,
  wavesurfer: null,
  showOptions :false,
  audioSelected: {},
  audioBlob:{}
};

const getters = {
  getAudioMute: state => state.muteunmute,
  getAudioState: state => state.audioState,
  getCurrentVolume: state => state.currentVolume,
  getDurationTime: state => state.durationTime,
  getElanState: state => state.loading,
  getQuestionSelectedLevel: state => state.questionSelectedLevel,
  getQuestionSelectedTitle: state => state.questionSelectedTitle,
  getWaveSurfer: state => state.wavesurfer,
  getShowOptions: state => state.showOptions,
  getAudioSelected: state => state.audioSelected,
  getAudioBlob: state => state.audioBlob
};

const actions = {
  async playItem({ commit, dispatch }, item) {
    state.loading = "invisible";
    state.audioState = true;
    state.audioSelected = item
    commit("SHOW_OPTIONS",true)
    commit("SELECTED_QUESTION", item[0].question);
    await axios
      .get(item[0].path)
      .then(response => {
        var data = response.data;
        dispatch("reproduceItem", data);
      })
      .catch(err => {
        console.log(err);
      });
  },

  getDuration({ commit }) {
    let durationTime = (state.wavesurfer.getDuration() / 60).toFixed(2);
    commit("SET_DURATION_TIME", durationTime);
  },

  async reproduceItem({ commit }, data) {
    state.wavesurfer.stop();
    let audio_type = data.filename.split(".");
    let binary = actions.createBinary(data.blob);
    let blob = new Blob([binary], {
      type: `audio/${audio_type[1]}`
    });
    state.audioBlob = blob
    commit("LOAD_BLOB", blob);
  },

  createBinary(dataURI) {
    let raw = window.atob(dataURI);
    let rawLength = raw.length;
    let arr = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i++) {
      arr[i] = raw.charCodeAt(i);
    }
    return arr;
  },

  async createWaveSurfer({ commit }) {
    var wavesurfer = WaveSurfer.create({
      audioRate: 1,
      backgroundColor: "#021a42",
      container: "#wave-form",
      cursorColor: "#24F205",
      hideScrollbar: false,
      progressColor: "#f39200",
      responsive: true,
      scrollParent: true,
      showTime: true,
      opacity: 1,
      waveColor: "#d4d4d4",
      plugins: [
        TimelinePlugin.create({
          container: "#wave-timeline"
        }),
        RegionPlugin.create()
      ]
    });
    commit("createWaveSurfer", wavesurfer);
  },

  playSelectedRow({ commit }, payload) {
    if (state.region != undefined) {
      state.region.remove();
    }
    let start = parseFloat(payload.start);
    let end = parseFloat(payload.end);
    state.region = state.wavesurfer.addRegion({
      start: start,
      end: end,
      resize: false,
      color: "rgba(223, 240, 216, 0.7)"
    });
    commit("PLAY_SELECTED_ROW", payload);
  },

  async stopsAudio({ commit }) {
    commit("STOPS_AUDIO");
    commit("RESET_QUESTION");
  },

  async playPauseAudio({ commit }) {
    commit("PLAY_AUDIO");
  },
  async rewindAudio({ commit }) {
    commit("REWIND_AUDIO");
  },
  async pauseAudio({ commit }) {
    commit("PAUSE_AUDIO");
  },
  async stopAudio({ commit }) {
    commit("STOP_AUDIO");
  },
  async forwardAudio({ commit }) {
    commit("FORWARD_AUDIO");
  },
  async muteAudio({ commit }) {
    commit("MUTE_AUDIO");
  },
  async removeVolume({ commit }) {
    commit("REMOVE_VOLUME");
  },
  async changeSpeed({ commit }, value) {
    commit("CHANGE_SPEED", value);
  },
  async loadAudio({ commit }, path) {
    commit("LOAD_AUDIO", path);
  },
  async changePauseIcon({ commit }, state) {
    commit("CHANGE_PAUSE_ICON", state);
  },
  async 0.75({ commit }) {
    commit("QUARTERX");
  },
  async 1({ commit }) {
    commit("NORMAL");
  },
  async 1.25({ commit }) {
    commit("HALFX");
  },
  async 1.5({ commit }) {
    commit("DOUBLE");
  },
  changueOptions({commit}){
    commit("SHOW_OPTIONS",false)
  }
};

const mutations = {
  createWaveSurfer: (state, wavesurfer) => {
    state.wavesurfer = wavesurfer;
  },

  SELECTED_QUESTION: (state, selectedQuestion) => {
    state.questionSelectedTitle = selectedQuestion.title;
    state.questionSelectedLevel = selectedQuestion.level;
  },
  RESET_QUESTION: state => {
    state.questionSelectedTitle = "";
    state.questionSelectedLevel = "";
    state.durationTime = "0.0";
  },
  SET_DURATION_TIME: (state, durationTime) => {
    state.durationTime = durationTime;
  },
  LOAD_BLOB: (state, blob) => {
    state.wavesurfer.loadBlob(blob);
  },
  PLAY_AUDIO: state => {
    if (state.audioState) {
      state.wavesurfer.play();
      state.audioState = false;
    } else {
      state.wavesurfer.pause();
      state.audioState = true;
    }
  },
  REWIND_AUDIO: state => {
    state.wavesurfer.skipBackward();
  },
  STOP_AUDIO: state => {
    state.wavesurfer.stop();
  },
  FORWARD_AUDIO: state => {
    state.wavesurfer.skipForward();
  },
  MUTE_AUDIO: state => {
    if (state.muteunmute) {
      state.muteunmute = false;
    } else {
      state.muteunmute = true;
    }
    state.wavesurfer.setMute(state.muteunmute);
  },
  REMOVE_VOLUME: state => {
    state.wavesurfer.setVolume(state.wavesurfer.getVolume() - 0.3);
    state.currentVolume = state.wavesurfer.getVolume();
  },
  CHANGE_SPEED: (state, value) => {
    state.wavesurfer.setPlaybackRate(value);
  },
  LOAD_AUDIO: (state, path) => {
    state.muteunmute = false;
    state.audioState = false;
    state.currentVolume = state.wavesurfer.getVolume();
    state.wavesurfer.load(path);
  },
  SET_ELAN_DATA: (state, data) => {
    state.wavesurfer.elan.data = data;
    state.wavesurfer.elan.render();
    state.loading = "visible";
  },
  SET_BEFORE_ROW: (state, data) => {
    state.wavesurfer.elan.container.scrollTop = data.offsetTop;
  },
  PLAY_SELECTED_ROW: (state, payload) => {
    state.audioState = false;
    let start = parseFloat(payload.start);
    let end = parseFloat(payload.end);
    state.wavesurfer.play(start, end);
  },
  CHANGE_PAUSE_ICON: (state, payload) => {
    state.audioState = payload;
  },
  QUARTERX: state => {
    state.wavesurfer.play();
    state.wavesurfer.setPlaybackRate(0.75);
    state.audioState = false;
  },
  HALFX: state => {
    state.wavesurfer.play();
    state.wavesurfer.setPlaybackRate(1.25);
    state.audioState = false;
  },
  NORMAL: state => {
    state.wavesurfer.play();
    state.wavesurfer.setPlaybackRate(1);
    state.audioState = false;
  },
  DOUBLE: state => {
    state.wavesurfer.play();
    state.wavesurfer.setPlaybackRate(1.5);
    state.audioState = false;
  },
  STOPS_AUDIO: state => {
    let playerPlaying = state.wavesurfer.isPlaying();
    if (playerPlaying === true) {
      state.wavesurfer.pause();
    }
    state.wavesurfer.empty();
  },
  DELETE_AUDIO:(state) => {
    state.showOptions = false
  },
  SHOW_OPTIONS:(state, newState) => {
    state.showOptions = newState;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
