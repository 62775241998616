<template>
  <v-card class="ma-2">
    <div id="wave-form">
    </div>
    <div id="wave-timeline">
    </div>
    <div id="wave-timeline"></div>
    <div id="player-controls" class="">
      <center>
        <button @click="rewindAudio">
          <i class="v-icon mdi mdi-rewind"></i>Rewind
        </button>
        <button @click="playPauseAudio" id="play">
          <div v-if="getAudioState">
            <i class="v-icon mdi mdi-play-circle"></i>
            Play
          </div>
          <div v-else>
            <i class="v-icon mdi mdi-pause-circle"></i>Pause
          </div>
        </button>
        <button @click="stopAudio">
          <i class="v-icon mdi mdi-stop"></i>Stop
        </button>
        <button @click="forwardAudio">
          <i class="v-icon mdi mdi-fast-forward"></i> Forward
        </button>
        <button @click="muteAudio">
          <div v-if="!volumeStatus">
            <i class="v-icon mdi mdi-music-note-off" ></i> Mute
          </div>
          <div v-else>
            <i class="v-icon mdi mdi-music-note"></i> Unmute
          </div>
        </button>
      </center>
    </div>

    <v-row class="ma-1">
      <v-col sm="12" class="d-flex justify-center">
        <v-chip
        class="ma-2"
        color="bbe-blue"
        label
        text-color="white"
        >
        <v-icon left>
          mdi-av-timer
        </v-icon>
        {{ this.getDurationTime }} minutes
      </v-chip>


      </v-col>
    </v-row>
    <v-alert
      color="primary"
      outlined
      type="warning"
      prominent
      v-if="volumeStatus"
    >
      Your audio is mute
    </v-alert>
  </v-card>
</template>

<script>

  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'Wavesurfer',
    data () {
      return {
        disableRemoveVolume: false,
        disableAddVolume: true,
        region: null,
        volumeStatus: undefined,
      }
    },
    methods: {
      ...mapActions([
        'createWaveSurfer',
        'loadAudio',
        'playPauseAudio',
        'rewindAudio',
        'stopAudio',
        'getDuration',
        'forwardAudio',
        'muteAudio',
        'removeVolume',
        'onProgress',
        'playSelectedRow',
        'changePauseIcon'
      ]),

      changePlayIcon(){
        this.changePauseIcon(true)
      }


    },
    mounted () {
      this.createWaveSurfer()
      // if you want to test with out treeview uncoment the next line
      // this.getWaveSurfer.load('https://ia801309.us.archive.org/34/items/auboutdufil-archives/104/myhandsyourneck.mp3');
      // this.getWaveSurfer.on('audioprocess', this.moveTimeProgress);
      this.getWaveSurfer.on('pause',  this.changePlayIcon)
      this.getWaveSurfer.on('ready',  this.getDuration)
      this.getWaveSurfer.on('ready',  this.playPauseAudio)
      this.getWaveSurfer.on('ready',  this.$vuetify.goTo(0))
    },

    computed: {
      ...mapGetters([
        "getAudioMute",
        "getAudioState",
        'getCurrentVolume',
        "getDurationTime",
        "getElanState",
        'getWaveSurfer',
      ])
    },
    watch: {
      getCurrentVolume(newVal){
        if (newVal <= 0.09999997913837433) {
          this.disableRemoveVolume = true
          this.disableAddVolume = false
          this.muteAudio
        } else if ((newVal > 0.09999997913837433) && (newVal < 3.0999996662139893 ) ) {
          this.disableRemoveVolume = false
        }else if (newVal >= 3.0999996662139893) {
          this.disableRemoveVolume = false
          this.disableAddVolume = true
        }
      },
      getAudioMute(newVal){
        if (newVal) {
          this.volumeStatus = true
        }else {
          this.volumeStatus = false
        }
      },
      getAudioState(newVal){
        if (newVal) {
          this.audioState = true
        }else {
          this.audioState = false
        }
      },

    }
  }
</script>

<style media="screen">
  button {
    color: #0E3740;
    padding: 1%;
  }
  #annotations {
    max-height: 280px;
    overflow: auto;
  }

  .wavesurfer-annotations tr.wavesurfer-active td {
    background-color: yellow;
  }

  .wavesurfer-time {
    width: 100px;
    color: #555;
  }

  .wavesurfer-tier-Text {
    width: 500px;
  }

  td.wavesurfer-tier-Comments {
    color: #999;
  }

  .wavesurfer-handle {
    background-color: #c9e2b3;
  }
  .visible {
    visibility: visible;
  }
  .invisible {
    visibility: hidden;
  }

  .element {
    @include invisible(visible);
  }
  .element {
    @include invisible(hidden);
  }
</style>
