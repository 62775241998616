<template>
  <div class="">
    <Loading v-if="isInfoLoading"/>
    <v-card v-else class="mx-3 my-5 pa-1">
      <Results :results="getResults" />
      <!-- this is for searching filter -->

      <v-toolbar color="bbe-blue" dark >

        <v-toolbar-title>PASSWORD SETTINGS</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-menu offset-y offset-yorigin="center center">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item  @click="editItem">
              <v-list-item-title>NEW ADMIN</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-card-title>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        ></v-text-field>
      </v-card-title>

      <!-- this is for listing the objects -->
      <v-card class="mx-1 my-1 pa-3">
        <v-data-table
          :headers="headers"
          :items="users"
          sort-by="name"
          :search="search"
          class="pa-1 ma-1">
          <!-- Button for new user -->
          <template v-slot:top>
            <v-dialog v-model="getUserDialogEdit" persistent>
              <Errors :errors="getErrors" />
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-main class="pa-1">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                        v-model="editedItem.password"
                        label="Password"
                        :type="'password'"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6">
                        <v-select
                        v-model="editedItem.user_role"
                        :items="getUserRoles"
                        item-text="name"
                        item-value="id"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select User Role"
                        chips
                        hint="The user role is not mandatory"
                        persistent-hint
                        ></v-select>
                      </v-col>

                      <v-col cols="6">
                        <v-checkbox
                        v-model="editedItem.admin"
                        label="Admin"
                        color="success"
                        hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-main>
                </v-card-text>

                <v-card-actions class="d-flex justify-center">
                  <v-btn class="bbe-blue" dark text @click="save">
                    Save
                  </v-btn>
                  <v-btn class="bbe-orange" dark text @click="close">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- The following dialog is for delete item -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  Student - Delete
                </v-card-title>
                <div class="text--primary text-center ma-2">
                  Are you sure you want to delete this item?
                </div>
                <v-card-actions class="justify-center">

                  <v-btn class="bbe-orange" dark text @click="deleteItemConfirm">
                    DELETE
                  </v-btn>
                  <v-btn class="bbe-blue" dark text @click="closeDelete">
                    CANCEL
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogShow" max-width="800px">
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  USER
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" class="font-weight-bold">
                        Email
                      </v-col>

                      <v-col cols="12" sm="6">
                        {{ editedItem.email }}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="2" class="font-weight-bold">
                        Admin
                      </v-col>

                      <v-col cols="12" sm="4">
                        {{ editedItem.admin }}
                      </v-col>

                      <v-col cols="12" sm="2" class="font-weight-bold">
                        User Role
                      </v-col>

                      <v-col cols="12" sm="4">
                        {{ editedItem.user_role }}
                      </v-col>
                    </v-row>


                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>

          <!-- The following are the icons for the CRUD actions -->
          <template v-slot:item.actions="{ item }" class="justify-around">
            <v-icon medium class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="showItem(item)">
              mdi-eye
            </v-icon>
            <v-icon medium @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Errors from "@/components/Errors";
import Results from "@/components/Results";
import Loading from "@/components/Loading";

export default {
  components: {
    Errors,
    Loading,
    Results
  },
  computed: {
    ...mapGetters([
      "getErrors",
      "getResults",
      "getUserRoles",
      "isInfoLoading",
      "getUserDialogEdit"
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    }
  },
  created() {
    this.initializeUser();
  },
  data() {
    return {
      defaultItem: {
        admin: "",
        email: "",
        password: "",
        user_role: "",
      },
      dialogEdit: false,
      dialogDelete: false,
      dialogShow: false,
      editedIndex: -1,
      editedItem: {
        admin: "",
        email: "",
        password: "",
        user_role: "",
      },
      headers: [
        { text: "Email", value: "email" },
        { text: "User Role", value: "user_role" },
        { text: "Admin", value: "admin" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      menuDate: false,
      search: ""
    };
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    setCompanyName: function(item){
      if (item.company === undefined || item.company === null) {
        return ""
      }else {
        return item.company.name
      }
    }
  },
  methods: {
    ...mapActions([
      "createUser",
      "deleteUser",
      "initializeUser",
      "updateUser",
      "setUserDialogEditStatus"
    ]),
    initialize () {
      this.initializeUser()
    },
    close() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.setUserDialogEditStatus();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      // this.users.splice(this.editedIndex, 1);
      this.deleteUser(this.editedItem);
      this.closeDelete();
    },
    editItem(item) {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.setUserDialogEditStatus();
    },
    save() {
      // if index is different to -1 means the record was modified
      if (this.editedIndex > -1) {
        this.updateUser(this.editedItem);
      } else {
        // this means is a new record
        this.createUser(this.editedItem);
      }
    },
    showItem(item) {
      this.showedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogShow = true;
    },
  },
  name: "User",
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
};
</script>
