<template id="">
  <div>
    <Language :languages="getLanguages" />
  </div>
</template>
<script>

import Language from "@/components/languages/Language";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Languages",
  data() {
    return { };
  },
  components: {
    Language
  },
  computed: {
    ...mapGetters(["getLanguages"])
  },
  methods: {
    ...mapActions(["loadLanguages"])
  },
  created() {
    this.loadLanguages();
  }
}
</script>
