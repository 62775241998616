<template>
  <div class="">
    <HomeAdmin v-if="getIsUserAdmin"/>
    <HomeTeacher v-if="getIsUserTeacher"/>
    <HomeStudent v-if="getIsUserStudent" />
    <Signin v-if="!getSignedIn" />
  </div>
</template>

<script>
import HomeAdmin from '@/views/HomeAdmin'
import HomeStudent from '@/views/HomeStudent'
import HomeTeacher from '@/views/HomeTeacher'
import Signin from '@/views/SignIn'
import { mapGetters } from "vuex";
export default {
  created() {
    this.getSignedIn;
  },
  data() {
    return { }
  },
  components: {
    HomeAdmin,
    HomeStudent,
    HomeTeacher,
    Signin
  },
  computed: {
    ...mapGetters([
      "getSignedIn",
      "getIsUserAdmin",
      "getIsUserStudent",
      "getIsUserTeacher",
    ])
  },
}
</script>

<style media="screen">

</style>
