<template>
  <div class="">
    <Errors :errors="getErrors" />
    <v-card >

      <!-- this is for searching filter -->
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="getNotGradedResponses"
        sort-by="name"
        :search="search"
        class="elevation-1"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import Errors from "@/components/Errors";


export default {
  created() {
    this.initializeNotGradedResponses();
  },
  components: {
    Errors,
  },
  computed: {
    ...mapGetters([
      "getErrors",
      "getNotGradedResponses"
    ]),
    formTitle() {
      return this.getEditedResponse === -1 ? "New Response" : "Edit Response";
    }
  },
  data(){
    return {
      headers: [
        { text: "Language", value: "language.name" },
        { text: "Student", value: "student.name" },
        { text: "Teacher", value: "teacher.name" }
      ],
      search: ""
    }
  },
  methods: {
    ...mapActions([
      "assignsTeacher",
      "loadTeachers",
      "initializeNotGradedResponses"
    ]),
  },
  name: "NotGradedResponses",

}

</script>
