<template>
  <!-- <v-responsive> -->
    <div class="d-flex justify-center align-center fill-height" >
      <v-card class="d-flex flex-column ma-2"  v-if="getExamActive" max-width="800" max-height="700" width="800">

        <v-card-title
        class="headline justify-center title-card white--text ma-2 pa-5" 
        >Nivel: {{ this.getQuestionLevel }}</v-card-title>

        <v-row class="ma-1 pa-2 ">
          <v-col sm="6" class="fill-height ma-0 pa-2" >
            <v-card class=" fill-height overflow-y-auto " max-height="400" tile flat>
              <div v-html='this.getQuestionTitle' class="fill-height" />
            </v-card>
          </v-col>

          <v-col sm="6" class="fill-height">
            <div class="fill-height">
              <Recorder/>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <v-card
      v-else
      class="d-flex flex-column my-5 py-5 px-2 justify-center container-test"
      width="800"
      elevation="0"
      >

      <v-row class="align-center">

        <v-col  xs="12" sm="6" md="6" lg="6" class="img-test">
          <v-img
          :src="imgNextStep"
          height="400px"
          contain
          ></v-img> 
        </v-col>
        <v-col>
          <v-card-title class="ma-auto text-center font-italic font-weight-bold container-title " style="font-size:1.5em">
            ¡Ya terminaste la prueba oral, estas a un paso de finalizar tu examen!
          </v-card-title>
          <v-card-subtitle class="my-2 mx-auto text-justify card-sub" >
            <p>  
            Las respuestas de tu examen oral quedaron grabadas. Para continuar con la prueba escrita, haz clic en el botón CONTINUAR y se abrirá la prueba en otra ventana.<b> No te salgas de la plataforma</b> ya que la prueba escrita quedará inhabilitada.
            </p>
            <p>
              Cualquier dificultad con las pruebas puedes remitirla al correo: <b> academico@bbelanguages.com.</b>
            </p>
          </v-card-subtitle>
          <v-card-actions class="justify-center">
            <v-btn
            rounded
            color="#00005B"
            dark
            @click="goTestFinish()"
            >
            Continuar
           </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
        <!-- <v-card-actions class="justify-center">
          <v-btn
          class="bbe-blue"
          dark
          @click="goTestFinish()"
          >
          Inicio
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </div>
<!-- </v-responsive> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Recorder from '@/components/Recorder'

export default {
  created(){
    this.initializeQuestionary();
    this.getNameStudentCompany()
  },
  components: {
    Recorder
  },
  computed: {
    ...mapGetters([
      "getExamActive",
      "getQuestionsToAnswer",
      "getQuestionLevel",
      "getQuestionTitle",
      "getPosition",
      "getCompanyStudent"
    ])
  },
  data() {
    return {
      algo: false,
      questionTitle: "",
      showContainer: false,
      showNexTest:false,
      thankyouImage: {
        id: 1,
        imageSrc: require('../assets/bogota.jpg')
      },
      imgNextStep:require('../assets/image_questionary.png')
    }
  },
  methods: {
    ...mapActions([
      "checkStep",
      "initializeQuestionary",
      "setNewPosition",
      "setQuestionLevel",
      "setQuestionTitle",
      "setStep",
      "goTest",
      "goNextTest",
      "getNameStudentCompany"
    ]),

    goTestFinish(){ 
      const finish = true
      if(this.getCompanyStudent){
        localStorage.setItem("test",true);
        this.goNextTest(finish)
        // router.push("/nextTest");
      }else{
        this.goTest(finish)
      }
    }
  },
  mounted() {
    // this.setStep();
  }
}
</script>

<style lang="scss" scoped>

#register {
  position: relative;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 15px 30px rgba(0, 0, 0, .2),
              0 10px 10px rgba(0,0,0,.2);
  &.close {
    width: 0;
    padding: 10px 0;
    overflow: hidden;
    transition: .8s ease-in-out;
    box-shadow: 0 16px 24px 2px rgba(0,0,0,.2);
  }
}


#thankyouImage {
  display: none;
  position: relative;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 15px 30px rgba(0, 0, 0, .2),
              0 10px 10px rgba(0,0,0,.2);
}


.showContainer {
  opacity: 1 !important;

  #inputProgress {
    width: 100%;
  }
}

p{
  color: black ;
}

.title-card{
  background-color: #213CB1;
  color: white;
}
.card-sub{
  padding: 3%;
}
.card-sub p{
  text-align: justify;
  // margin-bottom: ;
}
.container-test .container-title{
    word-break: normal;
}

@media (min-width: 320px) and (max-width: 765px)
{
  .container-test{
    width: 100%;
    height: 80%;
    padding: 0 3%;
  }

  .container-test .container-title{
    word-break: normal;
  }

  .img-test{
    height: 200px !important;
    display: none;
  }
}

</style>
