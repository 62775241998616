<template>
  <div class="">
    <Loading v-if="isInfoLoading"/>
    <v-card v-else class="mx-3 my-5 pa-1">
      <Errors :errors="getErrors" />
      <Results :results="getResults" />
      <v-toolbar color="bbe-blue" dark >
        <v-toolbar-title>ASSIGN TEACHER</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon @click="setUnassignedFilterActive()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-dialog v-model="getUnassignedFilterActive"  max-width="900" height='600'>
          <v-card>
            <v-card-title class="headline bbe-blue justify-center white--text">
              Filter
            </v-card-title>
            <v-card-text>
              <UnnasignedTeacherFilter />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <!-- this is for searching filter -->
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>

      <v-data-table
      v-model="selected"
      :headers="headers"
      :items="getUnassignedResponses"
      sort-by="name"
      :search="search"
      class="pa-1 ma-1"
      show-select
      >
        <template v-slot:item.created_at="{ item }">
          {{ item | setAnsweredAt }}
        </template>
      </v-data-table>

      <v-card class="blue-grey lighten-4 mb-3">
        <v-card-title class="teal justify-center white--text">
          SELECT TEACHER
        </v-card-title>
        <v-card-text class="pa-1">

          <v-row class="d-flex flex-row px-2">
            <v-col cols="10" class="my-auto">
              <v-select
              v-model="selectedTeacher"
              :items="getTeachers"
              item-text="name"
              item-value="id"
              label="Select Teacher"
              ></v-select>
            </v-col>
            <v-col cols="2" class="pa-0 my-auto">
              <v-btn class="bbe-blue justify-center fill-height" dark @click="sendAssignsTeacher()">
                ASSING TEACHER
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import Errors from "@/components/Errors";
import Results from "@/components/Results";
import Loading from "@/components/Loading";
import UnnasignedTeacherFilter from "@/components/responses/UnassignedTeacherFilter"

export default {
  created() {
    this.initializeUnassignedResponses();
    this.loadTeachers();
  },
  components: {
    Errors,
    Loading,
    Results,
    UnnasignedTeacherFilter
  },
  computed: {
    ...mapGetters([
      "getErrors",
      "getTeachers",
      "getUnassignedResponses",
      "isInfoLoading",
      "getResults",
      "getUnassignedFilterActive"
    ]),
    formTitle() {
      return this.getEditedResponse === -1 ? "New Response" : "Edit Response";
    }
  },
  data(){
    return {
      headers: [
        { text: "Student", value: "student.name" },
        { text: "Language", value: "language.name" },
        { text: "Company", value: "student.company.name" },
        { text: "Answered At", value: "created_at" },
      ],
      search: "",
      selected: [],
      selectedTeacher: ""
    }
  },
  filters: {
    setCompanyName: function(item) {
      if (item.student.company === undefined) {
        return "Not Company Found"
      } else {
        return item.student.company.name
      }
    },
    setAnsweredAt: function(item) {
      if (item.created_at === null) {
        return ""
      } else {
        let newDate = item.created_at.split("T");
        return newDate[0]
      }
    },
  },
  methods: {
    ...mapActions([
      "assignsTeacher",
      "loadTeachers",
      "initializeUnassignedResponses",
      "setUnassignedFilterActive"
    ]),
    sendAssignsTeacher() {
      let data = new Object()
      data['teacher_id'] = this.selectedTeacher
      data['responses'] = this.selected
      this.assignsTeacher(data)
      this.selectedTeacher = ""
      this.selected = []
    }
  },
  name: "UnassignedResponses",

}

</script>
