import api from "@/api";

const actions = {
  async createUser({ commit, dispatch }, user) {
    await api
      .createUser(user)
      .then(response => {
        commit("ADD_TO_USERS", response.data);
        commit("ADD_RESULTS", response.data);
        dispatch("setUserDialogEditStatus");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async deleteUser({ commit }, user) {
    await api
      .deleteUser(user)
      .then(response => {
        commit("REMOVE_FROM_USERS", response.data);
        commit("ADD_RESULTS", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async loadUsers({ commit, dispatch }) {
    await api
      .loadUsers()
      .then(response => {
        commit("LOAD_USERS", response.data);
        dispatch("changeIsLoadingState");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async loadUsersDropdown({ commit }) {
    await api.loadUsersDropdown().then(response => {
      commit("SET_USERS_DROPDOWN", response.data);
    });
  },
  async initializeUser({ commit, dispatch }) {
    commit("INITIALIZE_RESULTS");
    commit("INITIALIZE_ERRORS");
    commit("INITIALIZE_USER");
    dispatch("changeIsLoadingState");
  },
  async updateUser({ commit, dispatch }, user) {
    await api
      .updateUser(user)
      .then(response => {
        commit("UPDATE_USER", response.data);
        commit("ADD_RESULTS", response.data);
        dispatch("setUserDialogEditStatus");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  async updateIntegrityPolicy({commit}, integrityPolicy){
    
    await api
      .updateIntegrityPolicy(integrityPolicy)
      .then(response =>{
        commit("UPDATE_INTEGRITY_POLICY");
        commit("ADD_RESULTS", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  setUserDialogEditStatus({ commit }) {
    commit("SET_USER_DIALOG_EDIT_STATUS");
  }
};

const getters = {
  getUsers: state => state.users,
  getUserRoles: state => state.user_roles,
  getDropdownUsers: state => state.dropdownUsers,
  getUserDialogEdit: state => state.userDialogEdit
};

const mutations = {
  ADD_TO_USERS: (state, user) => {
    state.users.push(user.user);
  },
  INITIALIZE_USER: state => {
    state.users = [];
  },
  LOAD_USERS: (state, users) => {
    for (var i = 0; i < users.length; i++) {
      state.users.push(users[i]);
    }
  },
  REMOVE_FROM_USERS: (state, payload) => {
    let userIndex = state.users.findIndex(x => x.id === payload.user.id);
    state.users.splice(userIndex, 1);
  },
  SET_USERS_DROPDOWN: (state, users) => {
    for (var i = 0; i < users.length; i++) {
      state.dropdownUsers.push(users[i]);
    }
  },
  UPDATE_USER: (state, user) => {
    user = user.user;
    let userIndex = state.users.findIndex(x => x.id == user.id);
    state.users.splice(userIndex, 1, user);
  },
  SET_USER_DIALOG_EDIT_STATUS: state => {
    state.userDialogEdit = !state.userDialogEdit;
  },

  UPDATE_INTEGRITY_POLICY: state =>{
    state.integrityPolicy = true;
    localStorage.integrityPolicy = "false"
  }
};

const state = {
  dropdownUsers: [],
  userDialogEdit: false,
  user_roles: ["student", "teacher"],
  users: []
};

export default {
  actions,
  getters,
  mutations,
  state
};
