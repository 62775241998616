<template>
  <v-responsive>
    <div class="d-flex justify-center align-center fill-height ">
      <v-card
        class="d-flex flex-column ma-2 container-test"
        max-width="1200"
        max-height="900"
        width="950"
        height="650"
      >
        <v-card-title
          style="background-color:#005cf2; color:white"
          class="headline justify-center title-card  ma-2 pa-5"
          >Examen escrito</v-card-title
        >
        <v-row class="ma-1 pa-2 d-flex ">
          <v-col class="d-flex align-content-center ma-0 pa-2 img-container">
            <v-img
              :src="Image.imageSrc"
              height="450"
              max-height="600"
              max-width="400"
              min-height="300"
              min-width="200"
              position="center center"
            >
            </v-img>
          </v-col>
          <v-col class="fill-height ma-0 pa-2 container-instructions ">
            <v-card class="overflow-y-auto" max-height="500" tile flat>
              <div>
                <p><strong>Instrucciones para la prueba escrita </strong></p>
                <p><strong>Duración de la prueba:</strong> 45 min. aprox.</p>
                <p>
                  <strong>Usuario: </strong> No requiere – se realiza registro
                  de datos por el candidato
                </p>
                <p><strong>Instrucciones: </strong></p>
                <ol>
                  <li class="mb-2">Utilizar el navegador de Chrome.</li>
                  <li class="mb-2">
                    No cerrar nunca la pestaña con el examen.
                  </li>
                  <li class="mb-2">
                    Asegurar que la conexión es estable antes de pasar a otra
                    página o enviar el resultado (abrir una pestaña al lado para
                    asegurar que la red está cargando bien).
                  </li>
                  <li class="mb-2">
                    Completar toda la información personal incluyendo nombres,
                    correo electrónico, teléfono y compañía.
                  </li>
                  <li class="mb-2">
                    Para una evaluación exacta, por favor no usar ayudas
                    tecnológicas, diccionarios, o apoyo de personas externas
                  </li>
                </ol>
                <div style="text-align:center" width="100%">
                  <v-btn
                    :href="language.link"
                    class="btn button-submit mt-4"
                    rounded
                    target="_blank"
                    >Iniciar la prueba escrita</v-btn
                  >
                  <!-- <v-btn
                    class="btn bbe-orange white--text mt-2"
                    rounded
                    @click="goTestFinish()"
                    >Realizar otra prueba </v-btn> -->
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-responsive>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      Image: {
        id: 1,
        imageSrc: require("../assets/Recurso 17-100.jpg"),
      },
      language: {
        link: "",
      },
    };
  },
  created() {
    this.setLink();
  },
  computed: {
    ...mapGetters(["getStudentLanguages", "getResponse"]),
  },

  methods: {
    ...mapActions(["goHome", "goTest"]),
    setLink() {
      this.language = this.getStudentLanguages.find(
        (x) => x.id == this.getResponse.languageId
      );
    },
    goHomePage() {
      this.goHome();
    },

    goTestFinish() {
      const finish = true;
      this.goTest(finish);
    },
  },
  watch: {
    // cada vez que la pregunta cambie, esta función será ejecutada
    language: function() {
      this.language == undefined ? this.goHomePage() : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.button-submit {
  color: white !important;
  background-color: #005cf2 !important;
}
li {
  font-size: 0.95em;
}

@media (min-width: 320px) and (max-width: 765px) {
  .container-test {
    width: 100%;
    height: 80%;
    padding: 0 3%;
  }

  .img-container {
    display: none !important;
  }
}
</style>
