<template>
  <div>
    <Student :students="getStudents" />
  </div>
</template>

<script>
import Student from "@/components/students/Student";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Students",
  data() {
    return { };
  },
  components: {
    Student
  },
  computed: {
    ...mapGetters(["getStudents"])
  },
  methods: {
    ...mapActions(["loadStudents"])
  },
  created() {
    this.loadStudents();
  }
}
</script>
