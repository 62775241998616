<template>
  <div class="ma-1">
    <Errors :errors="getErrors" />
    <v-card>
      <video id="recorderView" playsinline class="video-js vjs-default-skin" style="width: 100%;">
        <p class="vjs-no-js">
          To view this video please enable JavaScript, or consider upgrading to a
          web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video.
          </a>
        </p>
      </video>

      <v-card-text class="d-flex flex-column justify-center align-center">

        <v-btn
          class="mx-2"
          fab
          dark
          large
          color="red"
          v-if="getIsRecording"
          @click="confirmRecording()"
        >
          <v-icon dark>
            mdi-checkbox-blank-circle
          </v-icon>
        </v-btn>


        <v-btn
          class="mx-2"
          fab
          dark
          large
          color="blue"
          v-if="getStopRecording"
          @click="stopsRecord()"
        >
          <v-icon dark>
            mdi-stop
          </v-icon>
        </v-btn>

      </v-card-text>


      <v-dialog
        v-model="startRecording"
        persistent
        max-width="400"
      >
        <v-card class="d-flex flex-column">
          <v-card-title class="headline yellow darken-4 white--text justify-center">
            Warning
          </v-card-title>
          <v-card-text class="pa-2 m-1 text-center">
            ¿Esta seguro de empezar la grabación?
          </v-card-text>
          <v-card-actions class="d-flex flex-row justify-center">
            <v-btn
              class="bbe-blue white--text"
              text
              @click="starts()"
            >
              Grabar
            </v-btn>
            <v-btn
              class="bbe-orange white--text"
              text
              @click="startRecording = false"
            >
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog
        v-model="getContinueQuestion"
        persistent
        max-width="400"
      >
        <v-card class="d-flex flex-column">
          <v-card-title class="headline green darken-4 white--text justify-center">
            GUARDADO
          </v-card-title>
          <v-card-text class="pa-2 text-center">
            POR FAVOR CONTINUAR A LA SIGUIENTE PREGUNTA.
          </v-card-text>
          <v-card-actions class="d-flex flex-row justify-center" v-if="getRecordSaved">
            <v-btn
              class="bbe-blue white--text"
              text
              @click="loadNextQuestion()"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-card>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import Errors from "@/components/Errors";

export default {
  data() {
    return {
      startRecording: false
    }
  },
  methods: {
    ...mapActions([
      "loadNextQuestion",
      "startsRecorder",
      "startsRecording",
      "submitVideo",
      "stopRecording"
    ]),

    stopsRecord() {
      this.getPlayer.record().stopDevice()
    },
    confirmRecording(){
      this.startRecording = true
    },
    starts(){
      this.startRecording = false
      this.startsRecording()
    }
  },
  components:{
    Errors
  },

  computed: {
    ...mapGetters([
      'getContinueQuestion',
      'getErrors',
      'getIsRecording',
      'getPlayer',
      'getRecordSaved',
      'getStopRecording'
    ])
  },

  mounted() {
    this.startsRecorder('recorderView');
    this.getPlayer.on('deviceReady', () => {
      this.getPlayer.record().start();
    });
    this.getPlayer.on('deviceError', () => {
      console.log('device error:', this.getPlayer.deviceErrorCode);
    });
    this.getPlayer.on('error', (element, error) => {
      console.error(error);
    });
    // user completed recording and stream is available
    this.getPlayer.on('finishRecord', () => {
      this.stopRecording();
    });
  },
  beforeDestroy() {
    if (this.getPlayer.player) {
      this.getPlayer.dispose();
    }
  }


}

</script>

<style media="screen">
.vjs-record .vjs-device-button.vjs-control {
  display: none;
}
</style>
