import api from "@/api";
import router from "@/router/index.js";

const actions = {
  async signOut({ commit }) {
    router.push('/');
    router.go(0);
    commit("SIGN_OUT");
  },
  
  async signOutTime({ commit }, error) {
    commit("SIGN_OUT");
    localStorage.error = JSON.stringify(error);
    router.go(0);
    router.push('/');
  },

  async signIn({ commit }) { 
    await api
      .signIn(state.currentUser)
      .then(response => {  
        if (response !== undefined && response.status === 200) {   
          commit("SIGN_IN", response.data);
          router.push("home");
        } else {
          let error = JSON.parse(localStorage.error);
          commit("ADD_ERRORS", error);
        }
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async signUp({ commit }) {
    await api
      .signUp(state.guestUser)
      .then(response => {
        if (response !== undefined && response.status === 200) {
          commit("ADD_RESULTS", response.data);
        } else {
          let error = JSON.parse(localStorage.error);
          commit("ADD_ERRORS", error);
        }
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  async getNameStudent({commit}){
    await api.getNameStudent(state.currentUser.email)
    .then(response =>{
      commit("GET_NAME_STUDENT", response.data);
    })
  },

  async getNameStudentCompany({commit}){
    await api.getNameStudentCompany(state.currentUser.email)
    .then(response => {
      commit("GET_NAME_STUDENT_COMPANY",response.data)
    })  
  }
};

const getters = {
  getSignedInUser: state => state.signedInUser,
  getCurrentUser: state => state.currentUser,
  getGuestUser: state => state.guestUser,
  getCompanyStudent: state => state.companyStudent,
  getNameStudentApi: state => state.isNameStudent,
  getIsUserAdmin: state => {
    if (state.signedInUser.admin == "true") {
      return true;
    } else {
      return false;
    }
  },

  getIsUserTeacher: state => {
    if (state.signedInUser.userRole === "teacher") {
      return true;
    } else {
      return false;
    }
  },

  getIsUserStudent: state => {
    if (state.signedInUser.userRole === "student") {
      return true;
    } else {
      return false;
    }
  },
};

const mutations = {
  SIGN_IN: (state, user) => { 
    localStorage.companyStudent = user.company
    localStorage.integrityPolicy = user.user.integrityPolicy
    // localStorage.setItem("userModel", JSON.stringify(user.user))
    localStorage.csrf = user.csrf;
    localStorage.idUser = user.user.id
    localStorage.signedIn = true;
    let admin = false;
    if (user.user.admin == true) {
      admin = true;
    }
    localStorage.admin = admin;
    localStorage.userEmail = user.user.email;
    if (user.user.user_role == null) {
      localStorage.userRole = "";
    } else {
      localStorage.userRole = user.user.user_role;
    }
    router.go(0);
  },

  SIGN_OUT: () => {
    delete localStorage.csrf;
    delete localStorage.signedIn;
    delete localStorage.admin;
    delete localStorage.userEmail;
    delete localStorage.userRole;
    delete localStorage.languageId;
    delete localStorage.idUser
    // delete localStorage.userModel
    delete localStorage.integrityPolicy
    delete localStorage.test
    delete localStorage.isNameStudent
  },

  GET_NAME_STUDENT:(state,name) => {
    localStorage.isNameStudent = name.estado
    state.isNameStudent = name.estado
  },
  GET_NAME_STUDENT_COMPANY:(state,name)=>{
    localStorage.companyStudent = name.company_name
    state.companyStudent = name.company_name
  }
};

const state = {
  isNameStudent:localStorage.getItem('isNameStudent'),
  companyStudent:localStorage.getItem('companyStudent'),
  currentUser: {
    email: "",
    password: ""
  },
  signedInUser: {
    admin: localStorage.getItem("admin"),
    email: localStorage.getItem("userEmail"),
    userRole: localStorage.getItem("userRole"),
  },
  guestUser: {
    id: "",
    address: "",
    cedula: "",
    cell_phone: "",
    city: "",
    company: "",
    company_id: "",
    email: "",
    language_ids: [],
    languages: [],
    name: "",
    programmed_date: "",
    response_email_to: "",
    access_code:"",
  },
};

export default {
  actions,
  getters,
  mutations,
  state
};
