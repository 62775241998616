<template>
  <div class="">
    <Loading v-if="isInfoLoading"/>
    <v-card v-else class="mx-3 my-5 pa-1">
      <Results :results="getResults" />
      <v-toolbar color="bbe-blue" dark >
        <v-toolbar-title>QUESTIONS</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showFilter()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-dialog v-model="getshowFilterActive"  max-width="900" height='600'>
          <v-card>
            <v-card-title class="headline bbe-blue justify-center white--text">
              Filter
            </v-card-title>
            <v-card-text>
              <QuestionFilter />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-menu offset-y offset-yorigin="center center">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item  @click="editItem">
              <v-list-item-title>NEW QUESTION</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <!-- this is for searching filter -->
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-card class="mx-1 my-1 pa-3">

        <v-data-table
        :headers="headers"
        :items="questions"
        sort-by="name"
        :search="search"
        class="pa-1 ma-1">

          <template v-slot:top>
            <v-dialog v-model="getQuestionDialogEdit" persistent>
              <Errors :errors="getErrors" />
              <!-- card for new/edit student -->
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-main class="pa-1">
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <v-select
                        v-model="editedItem.level"
                        :items="getDropdownQuestionLevels"
                        item-text="name"
                        item-value="id"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select Level"
                        chips
                        hint="Pick the level for the question"
                        persistent-hint
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                        v-model="editedItem.language_id"
                        :items="getDropdownLanguages"
                        item-text="name"
                        item-value="id"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select Language"
                        chips
                        hint="Pick the languages the student is going to do test"
                        persistent-hint
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <tiptap-vuetify
                        v-model="editedItem.title"
                        :extensions="getExtentions"/>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-main>

                <v-card-actions class="d-flex justify-center">
                  <v-btn class="bbe-blue" dark text @click="save">
                    Save
                  </v-btn>
                  <v-btn class="bbe-orange" dark text @click="close">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- The following dialog is for delete item -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  Student - Delete
                </v-card-title>
                <div class="text--primary text-center ma-2">
                  Are you sure you want to delete this item?
                </div>
                <v-card-actions class="justify-center">

                  <v-btn class="bbe-orange" dark text @click="deleteItemConfirm">
                    DELETE
                  </v-btn>
                  <v-btn class="bbe-blue" dark text @click="closeDelete">
                    CANCEL
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogShow" max-width="800px">
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  QUESTION
                </v-card-title>
                <v-card-text>
                  <v-main class="pa-1">

                    <v-row>
                      <v-col sm="2" class="font-weight-bold">
                        Level
                      </v-col>

                      <v-col sm="4">
                        {{ editedItem.level }}
                      </v-col>

                      <v-col sm="2" class="font-weight-bold">
                        Language
                      </v-col>

                      <v-col sm="4">
                        {{ editedItem.language }}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col sm="2" class="font-weight-bold">
                        Title
                      </v-col>

                      <v-col sm="10">
                        <span v-html="editedItem.title"></span> {{  }}
                      </v-col>
                    </v-row>

                  </v-main>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:item.title="{ item }">
            <span v-html="item.title"></span>
          </template>

          <!-- The following are the icons for the CRUD actions -->
          <template v-slot:item.actions="{ item }" class="justify-around">
            <v-icon medium class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="showItem(item)">
              mdi-eye
            </v-icon>
            <v-icon medium @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { TiptapVuetify } from "tiptap-vuetify";
import { mapGetters, mapActions } from "vuex";
import Errors from "@/components/Errors";
import Results from "@/components/Results";
import Loading from "@/components/Loading";
import QuestionFilter from "@/components/questions/QuestionFilter"

export default {
  created() {
    this.initializeQuestion();
    this.loadLanguagesDropdown();
    this.loadQuestionLevelsDropdown();
  },
  components: {
    Errors,
    Loading,
    QuestionFilter,
    Results,
    TiptapVuetify,
  },
  computed: {
    ...mapGetters([
      "getDropdownLanguages",
      "getDropdownQuestionLevels",
      "getErrors",
      "getExtentions",
      "getResults",
      "isInfoLoading",
      "getshowFilterActive",
      "getQuestionDialogEdit"
    ]),
    formTitle() {
      return this.editedItem === -1 ? "New Question" : "Edit Question";
    }
  },
  data(){
    return {
      defaultItem: {
        title: "",
        level: "",
        language_id: "",
        hide: ""
      },
      dialogEdit: false,
      dialogDelete: false,
      dialogShow: false,
      editedItem: {
        title: "",
        level: "",
        language_id: "",
        hide: ""
      },
      editedIndex: -1,
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Level', value: 'level' },
        { text: 'Language', value: 'language.name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      search: ""
    }
  },
  methods: {
    ...mapActions([
      "createQuestion",
      "deleteQuestion",
      "initializeQuestion",
      "loadLanguagesDropdown",
      "loadQuestionLevelsDropdown",
      "setShowFilterActive",
      "updateQuestion",
      "setQuestionDialogEditStatus"
    ]),
    close() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.setQuestionDialogEditStatus();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.questions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      // this.students.splice(this.editedIndex, 1);
      this.deleteQuestion(this.editedItem);
      this.closeDelete();
    },
    editItem(item) {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.editedIndex = this.questions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.setQuestionDialogEditStatus();
    },
    save() {
      // if index is different to -1 means the record was modified
      if (this.editedIndex > -1) {
        this.updateQuestion(this.editedItem);
      } else {
        // this means is a new record
        this.createQuestion(this.editedItem);
      }
    },
    showItem(item) {
      this.showedIndex = this.questions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogShow = true;
    },
    showFilter(){
      this.setShowFilterActive()
    },
  },
  name: "Question",
  props: {
    questions: {
      type: Array,
      required: true
    }
  }
}

</script>
<style scoped>
.editor p{
  text-align: left !important;
}
</style>
