<template>
  <div class="">
    <!-- <Loading v-if="isInfoLoading"/> -->
    <v-card class="mx-3 my-5 pa-1">
      <Errors :errors="getErrors" />
      <Results :results="getResults" />
        <v-container fluid>
          <v-row>
            <v-col>
             <v-toolbar color="bbe-blue" dark >
                <v-toolbar-title>Enable Test</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-btn icon >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn> -->
              </v-toolbar>

               <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                >
                </v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedResend"
                :headers="headers"
                :items="getStudents"
                sort-by="name"
                :search="search"
                class="elevation-1"                
              >
                  <template v-slot:[`item.created_at`]="{ item }">
                        {{ item | setAnsweredAt }}
                  </template>
                  <template v-slot:[`item.responses`]="{ item }">
                    <div class="d-flex flex-row align-center"> 
                    <div class="d-flex flex-column">
                        <div v-for="response in item.responses " :key="response.id"> 
                            <div class="mr-2" v-if="response.graded == true" >
                              <input type="checkbox" 
                                :value="response.language_id"
                                :id="response.id" 
                                style="width:22px; height:20px; margin-top:4px"
                                @change="merchantCategoryId($event,response,item)">
                              <label :for="response.id">{{getDropdownLanguages[response.language_id - 1].name}}</label> 
                            </div>
                            <!-- <div v-if="response.graded == false">
                              <p class="text-center">El examen de {{getDropdownLanguages[response.language_id - 1].name}} aun no ha sido respondido calificado </p>
                            </div> -->
                          </div>
                    </div>
                    </div>
                  </template>
                <!-- The following are the icons for the CRUD actions -->
                  <template v-slot:[`item.enable`]="{ item }" class="justify-around">
                    <v-btn class="bbe-blue" dark @click="saveEnable(item.id)">Enable test </v-btn>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }" class="justify-around">
                   <v-btn class="bbe-blue" dark @click="openModal(item)">Add language </v-btn>
                  </template>
              </v-data-table>

              <v-row class="">
                <!-- <v-col cols="12" class="d-flex justify-end">
                  <v-btn class="bbe-blue" dark @click="reSendReports()">
                     ENABLE 
                  </v-btn>
                </v-col> -->
              </v-row>
            </v-col>
          </v-row>
          <v-dialog v-model="dialogEdit" id="dialogEdit" persistent>
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  <span class="headline">Add languages to student</span>
                </v-card-title>
                <v-main class="pt-1">
                  <v-card-text>
                    <v-row>
                      <v-col sm="12">
                        <!-- <div v-for="module in itemSelected.languages" :key="module.id"> -->
                          <!-- {{module.name}} -->
                        <v-col cols="12">
                        <v-select
                        v-model="language_ids"
                        :items="getDropdownLanguages"
                        item-text="name"
                        item-value="id"
                        label="Select Languages"
                        :menu-props="{ top: true, offsetY: true }"
                        multiple
                        chips
                        hint="Pick the languages the student is going to do test"
                        persistent-hint
                        ></v-select>
                      </v-col>
                        <!-- </div> -->
                      </v-col>
                    </v-row>
                    <v-card-actions class="d-flex justify-center">
                      <v-btn class="bbe-blue" dark text @click="save()">
                        Save
                      </v-btn>
                      <v-btn class="bbe-orange" dark text @click="dialogEdit = false" >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                  </v-main>
              </v-card>
            </v-dialog>
        </v-container>
  </v-card>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import Errors from "@/components/Errors";
import Results from "@/components/Results";
// import Loading from "@/components/Loading";
export default {
  created() {
      this.loadStudentsEnable().then(()=>{
        this.getStudents = this.getStudents.map(student =>{
          let array = student.responses
          student.responses = array.map(response => {
            return [response.language_id, response]
          });
          student.responses = [...new Map(student.responses).values()];
          return student
        })
      })
      this.loadLanguagesDropdown()
  },
  components: {
    Errors,
    Results,
  },
  mounted:{

  },
  computed: {
    ...mapGetters([
      "getErrors",
      "getTeachers",
      "getResults",
      "isInfoLoading",
      "getStudents",
      "getDropdownLanguages"
    ]),
    formTitle() {
      return this.getEditedResponse === -1 ? "New Response" : "Edit Response";
    }
  },
  data(){
    return {
      headers: [
        { text: "Student", value: "name" },
        // { text: "Language", value: "language.name" },
        { text: "Company", value: "company.name" },
        { text: "Responses", value: "responses" },
        { text: "Enable", value: "enable" },
        { text: "Actions", value: "actions" },
      ],
      search: "",
      selected: [],
      selected1: [''],
      selectedResend:[],
      selectedLanguage:[],
      dialogEdit: false,
      dialogEnable: false,
      itemSelected:{
        language_ids:['']
      },
      language_ids:[],
      responsesEnable:[]
    }
  },
  methods: {
    ...mapActions([
      "hideResponse",
      "loadStudentsEnable",
      "loadLanguagesDropdown",
      "updateStudent",
      // "initializeUnsentResponses",
      // "initializeSentResponses",
      "sendsResponses",
      "setUnsentResponsesFilterActive",
      "setSentResponsesFilterActive",
      "enableTest"
    ]),
    openModal(item){
      this.itemSelected = item
      this.language_ids = [];
    if (
      item.languages !== undefined &&
      item.languages.length >= 0
    ) {
      for (var i = 0; i < item.languages.length; i++) {
       this.language_ids.push(item.languages[i].id);
      }
    }
      console.log(this.itemSelected)
      this.dialogEdit = true;
    },
    openModalEnable(item){
      console.log(item)
      this.IdEnableTest = item.id
      let array = item.responses
      this.responsesEnable = array.map(response => {
        return [response.language_id, response]
      });
      this.responsesEnable = [...new Map(this.responsesEnable).values()];
      this.dialogEnable = true
    },
    save(){

      let language_ids = []
      language_ids.push(this.language_ids)
      this.itemSelected.language_ids = this.language_ids
      this.updateStudent(this.itemSelected);
      this.dialogEdit = false
    },
    saveEnable(idEnable){
      console.log(idEnable)
      let arrayToSend = this.selectedResend.filter(item => {
        if(item.student == idEnable){
          return item
        }
      })
      console.log(arrayToSend)
      console.log(this.selectedResend)
      this.enableTest(arrayToSend)
      this.selectedTeacher = ""
      // this.selectedResend = []
      this.dialogEnable = false
    },
    merchantCategoryId($event,response,item){
       let student = [];
       let flag = true;
       let value =  [];
       value.push($event.target.value)
        this.selectedResend.map(x =>{
          if(x.student == response.id && $event.target.checked == true){
            flag = false
            x.language.push($event.target.value)
          } 
          return x
        })

        if($event.target.checked == true && flag == true){
          student = {
            student:item.id,
            language:value
          }
          this.selectedResend.push(student)
        }

        if($event.target.checked == false){     
          this.selectedResend.map(x=>{
            let index = x.language.map(y =>{
              y == $event.target.value
              x.language.splice(index,1)  
            })
            return x
          })
        }
        console.log(this.selectedResend)
    }
  },  
  filters:{
    setAnsweredAt: function(item) {
      let newDate = item.created_at.split("T")
      return newDate[0]
    },
  },
  name: "EnableTest",

}

</script>
