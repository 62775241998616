import api from "@/api";

const actions = {
  async createTeacher({ commit, dispatch }, teacher) {
    await api
      .createTeacher(teacher)
      .then(response => {
        commit("ADD_TO_TEACHERS", response.data);
        commit("ADD_RESULTS", response.data);
        dispatch("setTeacherDialogEditStatus");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  async initializeTeacher({ commit, dispatch }) {
    commit("INITIALIZE_ERRORS");
    commit("INITIALIZE_RESULTS");
    commit("INITIALIZE_TEACHER");
    dispatch("changeIsLoadingState");
  },

  async loadTeachers({ commit, dispatch }) {
    await api.loadTeachers().then(response => {
      commit("LOAD_TEACHERS", response.data);
      dispatch("changeIsLoadingState");
    });
  },
  async deleteTeacher({ commit }, teacher) {
    await api
      .deleteTeacher(teacher)
      .then(response => {
        commit("ADD_RESULTS", response.data);
        commit("REMOVE_FROM_TEACHER", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async updateTeacher({ commit, dispatch }, teacher) {
    await api
      .updateTeacher(teacher)
      .then(response => {
        commit("UPDATE_TEACHER", response.data);
        dispatch("setTeacherDialogEditStatus");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },

  setTeacherDialogEditStatus({ commit }) {
    commit("SET_TEACHER_DIALOG_EDIT_STATUS");
  }
};

const getters = {
  getTeachers: state => state.teachers,
  getTeacherDialogEdit: state => state.teacherDialogEdit
};

const mutations = {
  LOAD_TEACHERS: (state, payload) => {
    for (var i = 0; i < payload.length; i++) {
      state.teachers.push(payload[i]);
    }
  },
  ADD_TO_TEACHERS: (state, payload) => {
    state.teachers.push(payload.teacher);
  },
  INITIALIZE_TEACHER: state => {
    state.teachers = [];
  },
  REMOVE_FROM_TEACHER: (state, payload) => {
    let teacherIndex = state.teachers.findIndex(
      x => x.id === payload.teacher.id
    );
    state.teachers.splice(teacherIndex, 1);
  },
  UPDATE_TEACHER: (state, payload) => {
    let teachers = state.teachers;
    let teacher_to_replace = state.teachers.findIndex(
      x => x.id == payload.teacher.id
    );
    teachers[teacher_to_replace] = payload.teacher;
    state.teachers = [];
    for (var i = 0; i < teachers.length; i++) {
      state.teachers.push(teachers[i]);
    }
  },
  SET_TEACHER_DIALOG_EDIT_STATUS: state => {
    state.teacherDialogEdit = !state.teacherDialogEdit;
  }
};

const state = {
  teachers: [],
  teacherDialogEdit: false
};

export default {
  state,
  getters,
  actions,
  mutations
};
