<template>
  <v-card v-if="hasErrors()" class="mb-1 pa-1 red darken-4" dark>
    <v-card-title class="headline justify-center">
      ERROR
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <ul>
        <li v-for="(value, key) in errors" :key="key">
          {{ value }}
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      required: true
    }
  },
  methods: {
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    }
  }
};
</script>

<style scoped lang="scss">

</style>
