import api from "@/api";

const actions = {
  async createSetting({ commit, dispatch }, setting) {
    await api
      .createSetting(setting)
      .then(response => {
        commit("ADD_TO_LANGUAGES", response.data);
        commit("ADD_RESULTS", response.data);
        dispatch("setSettingDialogEditStatus");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async deleteSetting({ commit }, setting) {
    await api
      .deleteSetting(setting)
      .then(response => {
        commit("REMOVE_FROM_SETTING", response.data);
        commit("ADD_RESULTS", response.data);
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async loadSettings({ commit, dispatch }) {
    await api
      .loadSettings()
      .then(response => {
        commit("LOAD_LANGUAGES", response.data);
        dispatch("changeIsLoadingState");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  async loadSettingsDropdown({ commit }) {
    await api.loadSettingsDropdown().then(response => {
      commit("SET_LANGUAGES_DROPDOWN", response.data);
    });
  },
  async initializeSetting({ commit, dispatch }) {
    commit("INITIALIZE_RESULTS");
    commit("INITIALIZE_ERRORS");
    dispatch("changeIsLoadingState");
  },
  async updateSetting({ commit, dispatch }, setting) {
    await api
      .updateSetting(setting)
      .then(response => {
        commit("UPDATE_SETTING", response.data);
        commit("ADD_RESULTS", response.data);
        dispatch("setSettingDialogEditStatus");
      })
      .catch(error => {
        commit("ADD_ERRORS", error);
      });
  },
  setSettingDialogEditStatus({ commit }) {
    commit("SET_SETTING_DIALOG_EDIT_STATUS");
  }
};

const getters = {
  getSettings: state => state.settings,
  getDropdownSettings: state => state.dropdownSettings,
  getSettingDialogEdit: state => state.settingDialogEdit
};

const mutations = {
  ADD_TO_LANGUAGES: (state, setting) => {
    state.settings.push(setting.setting);
  },
  INITIALIZE_LANGUAGE: state => {
    state.settings = [];
  },
  LOAD_LANGUAGES: (state, settings) => {
    for (var i = 0; i < settings.length; i++) {
      state.settings.push(settings[i]);
    }
  },
  REMOVE_FROM_SETTING: (state, payload) => {
    let settingIndex = state.settings.findIndex(
      x => x.id === payload.setting.id
    );
    state.settings.splice(settingIndex, 1);
  },
  SET_LANGUAGES_DROPDOWN: (state, settings) => {
    for (var i = 0; i < settings.length; i++) {
      state.dropdownSettings.push(settings[i]);
    }
  },
  UPDATE_SETTING: (state, setting) => {
    setting = setting.setting;
    let settingIndex = state.settings.findIndex(x => x.id == setting.id);
    state.settings.splice(settingIndex, 1, setting);
  },
  SET_SETTING_DIALOG_EDIT_STATUS: state => {
    state.settingDialogEdit = !state.settingDialogEdit;
  }
};

const state = {
  dropdownSettings: [],
  settings: [],
  settingDialogEdit: false
};

export default {
  actions,
  getters,
  mutations,
  state
};
