<template>
  <div class="">
    <Loading v-if="isInfoLoading"/>
    <v-card v-else class="mx-3 my-5 pa-1">

      <Results :results="getResults" />
      <v-toolbar color="bbe-blue" dark >
        <v-toolbar-title>SETTINGS</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu offset-y offset-yorigin="center center">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item  @click="editItem">
              <v-list-item-title>NEW SETTING</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <!-- this is for searching filter -->
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-card class="mx-1 my-1 pa-3">
        <v-data-table
        :headers="headers"
        :items="settings"
        sort-by="name"
        :search="search"
        class="elevation-1">

          <template v-slot:top>

            <v-dialog v-model="getSettingDialogEdit" persistent>
              <Errors :errors="getErrors" />
              <!-- card for new/edit student -->
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-main class="pa-1">
                  <v-card-text>

                    <v-row>
                      <v-col sm="12">
                        <v-text-field v-model="editedItem.key" label="Key">
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col sm="1">
                        <p class="font-weight-regular">
                          Value
                        </p>
                      </v-col>
                      <v-col sm="11">
                        <tiptap-vuetify
                        v-model="editedItem.value"
                        :extensions="getExtentions"/>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-textarea v-model="editedItem.description" label="Description">
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-main>

                <v-card-actions class="d-flex justify-center">
                  <v-btn class="bbe-blue" dark text @click="save">
                    Save
                  </v-btn>
                  <v-btn class="bbe-orange" dark text @click="close">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- The following dialog is for delete item -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  Student - Delete
                </v-card-title>
                <div class="text--primary text-center ma-2">
                  Are you sure you want to delete this item?
                </div>
                <v-card-actions class="justify-center">

                  <v-btn class="bbe-orange" dark text @click="deleteItemConfirm">
                    DELETE
                  </v-btn>
                  <v-btn class="bbe-blue" dark text @click="closeDelete">
                    CANCEL
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogShow" max-width="800px">
              <v-card>
                <v-card-title class="headline justify-center bbe-blue white--text" >
                  SETTING
                </v-card-title>

                <v-card-text>
                  <v-container>

                    <v-row>
                      <v-col sm="2" class="font-weight-bold">
                        Key
                      </v-col>

                      <v-col sm="10">
                        {{ editedItem.key }}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col sm="2" class="font-weight-bold">
                        Value
                      </v-col>

                      <v-col cols="8" sm="8">
                        <div class="">
                          <span v-html="renderValue"></span>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col sm="2" class="font-weight-bold">
                        Description
                      </v-col>

                      <v-col cols="10" sm="8">
                        {{ editedItem.description }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>

          <!-- The following are the icons for the CRUD actions -->
          <template v-slot:item.actions="{ item }" class="justify-around">
            <v-icon medium class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="showItem(item)">
              mdi-eye
            </v-icon>
            <v-icon medium @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import Errors from "@/components/Errors";
import Results from "@/components/Results";
import Loading from "@/components/Loading";
import { TiptapVuetify } from 'tiptap-vuetify'

export default {
  created() {
    this.initializeSetting();
  },
  components: {
    Errors,
    Loading,
    Results,
    TiptapVuetify
  },
  computed: {
    ...mapGetters([
      "getErrors",
      "getExtentions",
      "isInfoLoading",
      "getResults",
      "getSettingDialogEdit"
    ]),
    formTitle() {
      return this.editedItem === -1 ? "New Setting" : "Edit Setting";
    },
    renderValue() {
      return this.editedItem.value
    }
  },
  data(){
    return {
      defaultItem: {
        description: "",
        key: "",
        value: "",
      },
      dialogEdit: false,
      dialogDelete: false,
      dialogShow: false,
      editedItem: {
        description: "",
        key: "",
        value: "",
      },
      editedIndex: -1,
      headers: [
        { text: "Key", value: "key" },
        { text: "Description", value: "description" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      search: "",

    }
  },
  methods: {
    ...mapActions([
      "createSetting",
      "deleteSetting",
      "initializeSetting",
      "setSettingDialogEditStatus",
      "updateSetting"
    ]),
    close() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.setSettingDialogEditStatus();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.settings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      // this.students.splice(this.editedIndex, 1);
      this.deleteSetting(this.editedItem);
      this.closeDelete();
    },
    editItem(item) {
      this.$store.commit("INITIALIZE_ERRORS")
      this.$store.commit("INITIALIZE_RESULTS")
      this.editedIndex = this.settings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.setSettingDialogEditStatus();
    },
    save() {
      // if index is different to -1 means the record was modified
      if (this.editedIndex > -1) {
        this.updateSetting(this.editedItem);
      } else {
        // this means is a new record
        this.createSetting(this.editedItem);
      }
    },
    showItem(item) {
      this.showedIndex = this.settings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogShow = true;
    }
  },
  name: "Setting",
  props: {
    settings: {
      type: Array,
      required: true
    }
  }
}

</script>
