<template>
  <v-app id="app">

    <v-system-bar
    app
    dark
    color="#336FE4"
    height="50"
    class="rounded-0">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="getSignedIn && getIsUserAdmin"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <img src="../assets/logo3.png" height="45px" width="165px" alt="">
      <!-- <div class="Bbe-principal-logo"></div> -->
      <v-spacer></v-spacer>
      <v-btn color="bbe-orange" class="white--text align-center" fab x-small @click="signOut()" v-if="getSignedIn">
        <v-icon icon class="ml-2">mdi-logout-variant</v-icon>
      </v-btn>
    </v-system-bar>
    <!-- Admin Toolbar -->
    <v-app-bar app color="bbe-gray" v-if="getSignedIn && getIsUserAdmin" class="d-none d-sm-block">
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn v-for="item in getAdminLinks" :key="item.key" :to="item.path" class="bbe-gray" text>
          <v-icon left>{{ item.icon }}</v-icon>
          {{ item.text }}
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <!-- Teacher Toolbar -->
    <v-app-bar app color="bbe-gray accent-4" v-if="getSignedIn && getIsUserTeacher">
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn v-for="item in getTeacherLinks" :key="item.key" :to="item.path" class="bbe-gray" text>
          <v-icon left>{{ item.icon }}</v-icon>
          {{ item.text }}
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" temporary class="d-none d-sm-flex" >
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="deep-orange--text text--accent-4">
          <v-list-item v-for="item in getNavBarLinks" :key="item.key" :to="item.path" >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer app v-model="drawer" temporary class="d-flex d-sm-none" >
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="deep-orange--text text--accent-4">
          <v-list-item v-for="item in getNavLinks" :key="item.key" :to="item.path" >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-content>
      <router-view></router-view>
    </v-content>
    <v-footer dark padless
    class="footer-app">
      <v-card
      flat
      tile
      width="100%"
      class="text-center"
      color ="#00005B"
      >
      <v-card-text>
        <v-btn
        v-for="icon in icons"
        :key="icon.icon"
        class="mx-4"
        icon
        x-small
        :href= icon.url
        target="_blank"
        >
        <v-icon >
          {{ icon.icon }}
        </v-icon>
      </v-btn>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-footer>
</v-app>

</template>


<script>

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
  },
  computed: {
    ...mapGetters([
      "getAdminLinks",
      "getDrawer",
      "getGroup",
      "getNavBarLinks",
      "getNavLinks",
      "getTeacherLinks",
      "getSignedIn",
      "getIsUserAdmin",
      "getIsUserTeacher"
    ])
  },
  data: () => ({
    drawer: false,
    group: null,
    icons: [
      {
        icon: 'mdi-facebook',
        url: 'https://www.facebook.com/bbelanguages'
      },
      {
        icon: 'mdi-twitter',
        url: 'https://twitter.com/bbelanguages'
      },
      {
        icon: 'mdi-linkedin',
        url: 'https://www.linkedin.com/company/bbe-languages'
      },
      {
        icon: 'mdi-instagram',
        url: 'https://www.instagram.com/bbelanguages/'
      }
    ],
  }),
  methods: {
    ...mapActions([
      "signOut",
    ])
  },
  watch: {
    group () {
      this.drawer = false
    },
  },
};
</script>
<style scoped>
  .Bbe-principal-logo{
    background:url("../assets/logo-new.png");
    background-size:145px 42px;
    background-repeat: no-repeat;
    background-position:center ;
    width: 200px;
    height: 40px;
    padding:10px;
  }

@media (min-width: 320px) and (max-width: 765px) {
  /* .footer-app{
    width:125vw;
  } */
}
</style>
