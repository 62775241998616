const actions = {
  changeDrawer({ commit }) {
    commit("CHANGE_DRAWER_STATE", state.drawer);
  }
};

const getters = {
  getAdminLinks: state => state.adminLinks,
  getDrawer: state => state.drawer,
  getGroup: state => state.group,
  getNavBarLinks: state => state.navBarLinks,
  getNavLinks: state => state.navLinks,
  getTeacherLinks: state => state.teacherLinks,
  getSignedIn: () => localStorage.signedIn
};

const mutations = {
  CHANGE_DRAWER_STATE: (state, drawer) => {
    state.drawer = !drawer;
  }
};

const state = {
  drawer: false,
  group: null,
  navLinks: [
    {
      icon: "mdi-home",
      key: "home",
      path: "/home",
      text: "Home"
    },
    {
      icon: "mdi-file-question",
      key: "response",
      path: "/responses",
      text: "Responses"
    },
    {
      icon: "mdi-teach",
      key: "teacher",
      path: "/teachers",
      text: "Teachers"
    },
    {
      icon: "mdi-cloud-question",
      key: "question",
      path: "/questions",
      text: "Questions"
    },
    {
      icon: "mdi mdi-cog",
      key: "setting",
      path: "/settings",
      text: "Settings"
    },
    {
      icon: "mdi mdi-account",
      key: "student",
      path: "/students",
      text: "Students"
    },
    {
      icon: "mdi-factory",
      key: "company",
      path: "/companies",
      text: "Companies"
    },
    {
      icon: "mdi-translate",
      key: "language",
      path: "/languages",
      text: "Languages"
    },
    {
      icon: "mdi-account-circle",
      key: "user",
      path: "/users",
      text: "Password Settings"
    }
  ],
  teacherLinks: [
    {
      icon: "mdi-home",
      key: "home",
      path: "/home",
      text: "Home"
    },
    {
      icon: "mdi-file-question",
      key: "response",
      path: "/responses",
      text: "Responses"
    }
  ],
  adminLinks: [
    {
      icon: "mdi-home",
      key: "home",
      path: "/home",
      text: "Home"
    },
    {
      icon: "mdi-factory",
      key: "company",
      path: "/companies",
      text: "Companies"
    },
    {
      icon: "mdi-file-question",
      key: "response",
      path: "/responses",
      text: "Responses"
    },
    {
      icon: "mdi mdi-account",
      key: "student",
      path: "/students",
      text: "Students"
    }
  ],
  navBarLinks: [
    {
      icon: "mdi-translate",
      key: "language",
      path: "/languages",
      text: "Languages"
    },
    {
      icon: "mdi-cloud-question",
      key: "question",
      path: "/questions",
      text: "Questions"
    },
    {
      icon: "mdi mdi-cog",
      key: "setting",
      path: "/settings",
      text: "Settings"
    },
    {
      icon: "mdi-teach",
      key: "teacher",
      path: "/teachers",
      text: "Teachers"
    },
    {
      icon: "mdi-account-circle",
      key: "user",
      path: "/users",
      text: "Password Settings"
    }
  ]
};

export default {
  actions,
  getters,
  mutations,
  state
};
