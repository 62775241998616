<template id="">
  <div class="">
    <Company :companies="getCompanies" />
  </div>
</template>
<script>
import Company from "@/components/companies/Company";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Companies",
  data() {
    return { };
  },
  components: {
    Company
  },
  computed: {
    ...mapGetters(["getCompanies"])
  },
  methods: {
    ...mapActions(["loadCompanies"])
  },
  created() {
    this.loadCompanies();
  }
}
</script>
