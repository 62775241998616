import Vue from "vue";
import Vuex from "vuex";
import Company from "./modules/companies";
import Global from "./modules/globals";
import Header from "./modules/header";
import Language from "./modules/languages";
import Questionary from "./modules/questionary";
import Question from "./modules/questions";
import Response from "./modules/responses";
import Recorder from "./modules/recorder";
import Sessions from "./modules/sessions";
import Setting from "./modules/settings";
import Student from "./modules/student";
import Teacher from "./modules/teacher";
import Treeview from "./modules/treeview";
import User from "./modules/users";
import WaveSurfer from "./modules/wavesurfer";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    plugins: [createPersistedState()],
    Company,
    Global,
    Header,
    Language,
    Questionary,
    Question,
    Recorder,
    Response,
    Sessions,
    Setting,
    Student,
    Teacher,
    Treeview,
    User,
    WaveSurfer
    // wavesurfer
  }
});
