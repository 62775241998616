<template>
  <div>
    <Teacher :teachers="getTeachers" />
  </div>
</template>

<script>
// Components
import Teacher from "@/components/teachers/Teacher";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Teachers",
  data() {
    return { };
  },
  computed: {
    ...mapGetters(["getTeachers"]),
  },
  components: {
    Teacher
  },
  methods: {
    ...mapActions(["loadTeachers"])
  },
  created() {
    this.loadTeachers();
  }
};
</script>

<style lang="scss" scoped>
.spinner {
  font-size: 7rem;
  text-align: center;
}
</style>
